<template >
    <div>
        <div class="jcsb w270">
            <div :class="active == 1 ? 'buttonActive' : 'button'" class=" tc lh40 cp cf" @click="handeclickFn(1)">
                单位排名
            </div>
            <div :class="active == 2 ? 'buttonActive' : 'button'" class=" ml15 tc lh40 cp cf" @click="handeclickFn(2)">
                个人排名
            </div>
        </div>
       <div class="table_box_style">
        <el-table     height="240" :data="active == 1 ? dataSource1 : dataSource2" class="mt20 hwb-table-new"
            :header-row-style="{ 'background-color': 'rgba(138,152,177,0.28)', color: '#fff', 'font-size': '14px' }"
            :header-row-class-name="'header-row-class-name'" :max-height="550" :row-class-name="tableRowClassName"
            row-key="id" :tree-props="{ children: 'child', hasChildren: 'hasChildren' }"
            :cell-style="{ textAlign: 'center' }" :highlight-current-row="false"
            :header-cell-style="{ textAlign: 'center' }">
            <el-table-column type="index" label="排名" :width="160"></el-table-column>
            <el-table-column :prop="active==1?'organizationName':'name'" :label="active==1?'单位名称':'姓名'"></el-table-column>
            <el-table-column prop="taskCount" label="飞行总任务数"></el-table-column>
            <el-table-column prop="duration" label="飞行总时长"></el-table-column>
            <el-table-column prop="distance" label="飞行总里程"></el-table-column>

            <el-table-column prop="lastFlyTime" label="最新飞行时间">
            </el-table-column>
        </el-table>
       </div>
       
    </div>
</template>
<script>
import API from "@/api";
export default {
    data() {
        return {
            active: 1,
            pagination: {
                page: 1,
                pageSize: 5,
                total: 0,
            },
            dataSource1: [{}, {}],
            dataSource2: [],
        }
    },
    created() {
        this.init()
    },
    methods: {
        handeclickFn(num) {
            this.active = num
        },
        async init() {
            // 机构任务数量排行
            let res = await API.FLIGHTMANAGEMENT.organRank({
                pageNo: 1,
                pageSize: 5000
            })
            if (res.code == 200) {
                this.dataSource1 = res.data
            }else{
                this.dataSource1 = []

            }


            // 個人
            let res2 = await API.FLIGHTMANAGEMENT.statisticsRank({
                page: 1,
                page_size: 5000
            })
            if (res2.code == 200) {
                this.dataSource2 = res2.data.records
            }else{
                this.dataSource2 = []
            }

        },
        tableRowClassName({ row, rowIndex }) {  // 控制任务状态
            if (rowIndex % 2 == 0) {
                return 'dan-row';
            } else if (rowIndex % 2 == 1) {
                return 'shuang-row';
            }
            return '';
        },
    },
}
</script>
<style lang="scss" scoped>
.table_box_style{
    
}
.buttonActive {
    width: 126px;
    height: 40px;
    background: rgba(38, 71, 238, 0.71);
    box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
    border-radius: 6px;
    border: 1px solid;
    // border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
}

.button {
    width: 126px;
    height: 40px;
    background: #0c1954;
    box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
    border-radius: 6px;
    border: 1px solid;
    // border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
}
</style>