<template >
    <div>
        <Dialog  @on-close="closeIDsPopup" title="人员管理" :left="140" :top="105" :width="1748">
        <div class="jcsb h340">
    <createUser style="width:50%"></createUser>
    <Histogram style="width:50%">
    </Histogram>
        </div>
      </Dialog>
      <Dialog  :ifclose='false' @on-close="closeIDsPopup" title="人员统计" :left="140" :top="555" :width="862">
        <div class="h260">
          <Statistics   ></Statistics>

        </div>
      </Dialog>
      <Dialog :ifclose='false'    title="绩效排名" :left="1030" :top="555" :width="862">
        <div class=" h280">
          <Achievements></Achievements>
        </div>
      </Dialog>
    </div>
</template>
<script>
import Dialog from '../dialog'; // 弹框组件
import createUser from './components/createUser'; // 人员管理
import Statistics from './components/statistics'; // 人员统计
import Achievements from './components/achievements'; // 人员统计
import Histogram from './components/Histogram'; // 弹框组件
import API from "@/api";

export default {
    components: {
    Dialog,
    createUser,
    Histogram,
    Statistics,
    Achievements
  },
    data() {
        return {
            
        }
    },
    methods: {
        closeIDsPopup() {
      this.$emit('closeDemand');
    },
    create() {
      this.Create = true
      this.initV()
    },
    },
}
</script>
<style lang="">
    
</style>