<template>
  <div class="ball_box">
    <!--    姿态球-->
    <div class="ball">
      <img
        class="img4"
        src="@/assets/img/ball4v2.png"
        :style="{
          transform: `scale(2)rotate(${flyData.roll||0}deg)translate(0,${flyData.pitch||0}px)`,
        }"
      />
      <img
        class="img"
        src="@/assets/img/ball3.png"
        :style="{ transform: `rotate(${flyData.yaw||0}deg)` }"
      />
      <img class="img2" src="@/assets/img/ball2.png" />
      <img class="img1" src="@/assets/img/ball1.png" />
      <div class="keducon">
        <img class="kedu" src="@/assets/img/kedu1.png" />
      </div>
    </div>
  </div>
</template>
<script >
export default {
  data() {
    return {};
  },
  props: {
        'flyData': {
            type: Object,
            default: () => "middle",
        }
    },
  methods: {},
  computed: {

  },
};
</script>
<style scoped lang="scss">
.ball_box {
  box-sizing: border-box;
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: space-between;
  .ball {
    height: 100%;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    z-index: 1;
    .img {
      height: 100%;
      position: inherit;
      transition: 1s;
    }

    .img4 {
      height: 100%;
      position: absolute;

}
    .keducon {
      height: 80%;
      width: 80%;
      position: absolute;
      border-radius: 50%;
      overflow: hidden;
      left: 10%;
      top: 10%;
    }
    .kedu {
      position: absolute;
      width: 125%;
      height: 125%;
      left: -10%;
      top: -12%;
      z-index: 2;
    }

    .img2 {
      position: absolute;
      left: calc(49%);
      width: 5px;
      height: 10px;
    }
    .img1 {
      height: 20px;
      width: 72px;
      position: absolute;
      z-index: 3;
      top: calc(50% - 20px);
      left: calc(50% - 36px);
    }
  }
  .position {
    height: 100%;
    width: 58.1%;
    margin-left: 4.6%;
    background: #296d97;
    box-shadow: 0 2px 8px 1px rgba(11, 48, 77, 0.7);
    border-radius: 10px;
    .first {
      display: flex;
      height: 50%;
      align-items: center;

      svg {
        margin-left: 2rem /* 32/16 */;
      }

      .text {
        margin-left: 2rem /* 32/16 */;
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #d9ebff;
        letter-spacing: 0;
        text-align: left;
        font-weight: 400;
      }
    }

    .divider {
      border-top: 1px solid #0e4465;
      height: 1px;
      width: 100%;
    }
  }
}
</style>
