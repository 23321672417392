<template>
  <div class="task-flightManagement">
    <div class="flightManagement-menu">
      <Menu ref="menuRef" :iconList="list" @on-Click="showList"/>
    </div>
    <Airspace v-if="showListType==1" @closeDemand="closeDemand"/>
    <!-- 绘制航线 -->
    <RouteDrawing v-if="showListType == 2" @openList="closeDemand"/> 
    <!-- 飞行成果 -->
    <achievementLibrary v-if="showListType==3" @closeDemand="closeDemand"/>
    <!-- 飞行日志 -->
    <FlightLog v-if="showListType==4" @closeDemand="closeDemand"/>
    <Personnel_ v-if="showListType==5" @closeDemand="closeDemand"/>
    <warningStatistics v-if="showListType==6" @closeDemand="closeDemand"/>
  </div>
</template>

<script>
import Menu from "@/components/newmenu/menu";
import Airspace from "./airspace";
import RouteDrawing from "@/pages/new/dispatch/waiting/RouteDrawing"
import achievementLibrary from "./achievementLibrary/index.vue"; // 飞行成果
import FlightLog from "./flightLog/index.vue"; // 飞行日志
import Personnel_ from "./personnel_/index.vue"; // 飞行日志
import warningStatistics from "./warningStatistics"; // 飞行日志


export default {
  components: { Menu, Airspace, achievementLibrary, FlightLog , RouteDrawing ,Personnel_,warningStatistics},
  data() {
    return {
      list: [
      ],
      isShow: false,
      showListType:false, // 控制展示左侧哪个导航
    };
  },
  mounted() {
    if(this.$route.params.id){
  this.$refs.menuRef.select(this.$route.params)
  }else{
    if (this.list.length) this.$refs.menuRef.select(this.list[0])
  }
},
  methods: {
    closeDemand() {
      this.showListType = false;
    },
    showList(item) {
      this.showListType = item.id;
    },
    init_jurisdiction(){
      const permissions = JSON.parse(localStorage.getItem("permissions"));
    const permissionList = permissions.map((item) => item.permissionSign);
    if (permissionList.indexOf("hn:tasks:airspace") > -1) {
      this.list.push({
          id: 1,
          name: "空域管理",
          class: "icon-youxiao",
        })
    }
    if (permissionList.indexOf("hn:tasks:draw") > -1) {
      this.list.push({
          id: 2,
          name: "绘制航线",
          class: "icon-shenpi",
        })
    }
    if (permissionList.indexOf("jy:tasks:personnel_") > -1) {
      this.list.push({
          id: 5,
          name: "人员管理",
          class: "icon-shenpi",
        })
    }
    if (permissionList.indexOf("hn:tasks:result") > -1) {
      this.list.push({
          id: 3,
          name: "飞行成果",
          class: "icon-shenpi",
        })
    }
    if (permissionList.indexOf("hn:tasks:flightlog") > -1) {
      this.list.push({
          id: 4,
          name: "飞行日志",
          class: "icon-shenpi",
        })
    }
    if (permissionList.indexOf("hn:tasks:flightlog") > -1) {
      this.list.push({
          id: 6,
          name: "预警统计",
          class: "icon-shenpi",
        })
    }

    // this.showListType = this.list[0]?.id
    }
  },
  created(){
    this.init_jurisdiction()
  }
};
</script>

<style lang="scss" scoped>
.task-flightManagement {
  .flightManagement-menu {
    position: absolute;
    left: 0;
    z-index: 1;
    width: 108px;
    height: 100%;
  }
}
</style>