<template>
  <div>
    <el-table
      height="280"
      :data="userList"
      class="mt20 hwb-table-new"
      :header-row-style="{
        'background-color': 'rgba(138,152,177,0.28)',
        color: '#fff',
        'font-size': '14px',
      }"
      :header-row-class-name="'header-row-class-name'"
      :max-height="550"
      :row-class-name="tableRowClassName"
      row-key="name"
      :tree-props="{ children: 'childs', hasChildren: 'hasChildren' }"
      :cell-style="{ textAlign: 'center' }"
      :highlight-current-row="false"
      :header-cell-style="{ textAlign: 'center' }"
    >
      <el-table-column
        prop="name"
        label="单位名称"
        :width="160"
      ></el-table-column>
      <el-table-column prop="userCount" label="人员总数"></el-table-column>
      <el-table-column prop="pacent" label="人员占比">
        <template slot-scope="scope">
          {{ setPacent(scope.row.pacent) }}%
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import API from "@/api";
export default {
  data() {
    return {
      userList: [],
      pagination: {
        page: 1,
        pageSize: 5,
        total: 0,
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    setPacent(data) {
      if (data) {
        let newData = data.replace("%", "");
        if(newData){
            return Number(newData).toFixed(1)
        }
        console.log(newData,'newData');
      }
      return data;
    },
    async init() {
      // flightManagement
      let res = await API.FLIGHTMANAGEMENT.organUserCount({
        pageNo: 1,
        pageSize: 500,
      });
      if (res.code == 200) {
        this.userList = res.data;
        console.log(res.data, "res.data");
      }
    },
    tableRowClassName({ row, rowIndex }) {
      // 控制任务状态
      if (rowIndex % 2 == 0) {
        return "dan-row";
      } else if (rowIndex % 2 == 1) {
        return "shuang-row";
      }
      return "";
    },
  },
};
</script>
<style lang="scss" scoped>
.button {
  width: 126px;
  height: 40px;
  background: rgba(38, 71, 238, 0.71);
  box-shadow: inset 0px 1px 3px 0px #ffffff,
    inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
  border-radius: 6px;
  border: 1px solid;
  // border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
}
</style>
