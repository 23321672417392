let EventListener = {}
export default {
    // 监听视频播放进度
    eventTester(e) {
        let video = this.player
        let _this = this
        let { FilghtMapData } = this
        video.on('timeupdate', function () {//播放时间改变
            let { positionTime, orientationTime, flyTimeData } = _this.modelDataTime
            let time = parseInt(_this.startTime)
            let newTime = parseInt(video.currentTime() + time)
            if (positionTime[newTime]) {
                _this.modelData.position = positionTime[newTime]
            }
            if (orientationTime[newTime]) {
                _this.modelData.orientation = orientationTime[newTime]
            }
            if (flyTimeData[newTime]) {
                _this.flyTimeData = flyTimeData[newTime]
            }

            // 改变时间
            _this.type = 'suspend';
            _this.percentage = video.currentTime() * (100 / video.duration())
            _this.videoConroller.startTime = _this.timeCycleFilter(video.currentTime())
            // 播放完成
            if (_this.percentage >= 100) {
                video.pause()
                _this.type = 'play';
                // 切换视频
                _this.changeVideo('nest')
            }
        });
    },
    // 切換視頻
    changeVideo(type) {
        let { videoIndex, videoList } = this;
        if (type == 'previous') {
            if (videoIndex == 0) {
                return
            } else {
                ;
                this.videoIndex--;
                this.changeVideoUrl()
            }
        } else {
            if (videoIndex != videoList.length - 1) {
                this.videoIndex++;
                this.changeVideoUrl()
            }
        }
    },
    changeVideoUrl() {
        this.$nextTick(() => {
            let { videoIndex, videoList } = this;
            this.player.src(videoList[videoIndex].url)
            // 切割时间
            this.TimeRange(this.player.duration())
            this.player.play()
            setTimeout(() => {
                let time = this.timeCycleFilter(this.player.duration())
                this.videoConroller.endTime = time
            }, 2000);
        })

    },
    init() {
        let _this = this
        // 播放器初始化
        this.$nextTick(() => {
            this.player = videojs('videoPlayMap', this.options, function () {
                // 改变样式播放按钮样式
                _this.type = 'suspend'
                // 获取当前播放时间 00:00
                _this.videoConroller.startTime = _this.timeCycleFilter(_this.player.currentTime())
                console.log(_this.player, '_this.player');
                if (_this.player) {
                    // 自动播放
                    this.play()
                    //
                    let duration = _this.player.duration()
                    if (duration == duration) {
                        let time = _this.timeCycleFilter(duration)
                        _this.videoConroller.endTime = time
                        // 切割时间
                        _this.TimeRange(duration)
                        // 监听播放时间
                        if (!EventListener['timeupdate']) {
                            _this.eventTester("timeupdate");
                        }
                    } else {
                        setTimeout(() => {
                            _this.init()
                        }, 2000);

                    }


                }
            })
        })
    },
    // 控制视频 
    handplay(type) {
        let video = this.player
        // 視頻控制
        if (type == 'play' && video) {
            video.play()
            this.type = 'suspend'
            // 开始时间
            this.videoConroller.startTime = this.timeCycleFilter(video.currentTime())
            let time = this.timeCycleFilter(video.duration())
            this.videoConroller.endTime = time
            this.TimeRange(video.duration())
            // 记录播放时间
            if (!this.EventListener['timeupdate']) {
                this.eventTester("timeupdate");
            }
        } else {
            video.pause()
            this.type = 'play'
        }
    },
    // 分割时间
    TimeRange(duration) {
        let num = duration / 12;
        let num2 = 0;
        let arr = []
        for (let index = 0; index < 12; index++) {
            arr.push({
                time: num + num2,
                num: this.timeCycleFilter(num + num2)
            })
            num2 = num + num2
        }
        this.timeArr = arr
    },

    // 切换播放时间
    videoChangeTime(item) {
        let video = this.player
        if (video && item.time) {
            video.currentTime(item.time)
            this.videoConroller.startTime = item.num
            this.percentage = video.currentTime() * (100 / video.duration())
            this.type = 'suspend'
            video.play()
        }
    },
    // 转换时间格式
    timeCycleFilter(sec) {
        let hour = Math.floor(sec / 3600);
        let minute = Math.floor((sec - hour * 3600) / 60);
        let second = Math.floor(sec - hour * 3600 - minute * 60);
        if (hour < 10) {
            hour = "0" + hour;
        }

        if (minute < 10) {
            minute = "0" + minute;
        }

        if (second < 10) {
            second = "0" + second;
        }

        if (hour == 0) {
            return minute + ":" + second;
        } else {
            return hour + ":" + minute + ":" + second;
        }

    },
    // 切换播放速度
    videoSpeedChange(Speed) {
        try {
            this.player.playbackRate(Speed)
        } catch (error) {

        }
    },

}