<template>
  <div>
    <!-- 飞行成果列表 -->
    <Dialog class="cgk" @on-close="closeIDsPopup" :closeTitle="'返回'" :title="'飞行成果'" :width="1490" :top="120" :left="120"
      style="zIndex:1">
      <!-- 赛选栏 -->
      <div class="status-select hwb-flex">
        <div class="button" :class="status === 2 ? 'button-active' : ''" @click="changeStatus(2)">任务报告</div>
        <div class="button" :class="status === 1 ? 'button-active' : ''" @click="changeStatus(1)">飞行成果</div>
      </div>
      <!-- 内容 -->
      <div class="content">
        <!-- 一条需求单样式 -->
        <div class="one-box" v-for="(item, index) in dataList" :key="index">
          <div class="img-box">
            <el-popover placement="right" width="40" popper-class="hwb-achievement-library-popover"
              v-model="item.boolean">
              <div class="action-list">
                <div @click="handle_review(item)" v-if="item.typeId != 4" class="action-item">查看</div>
                <div @click="handle_download(item)" class="action-item">
                  下载
                </div>
                <div @click="handle_before_rename(item)" class="action-item">
                  重命名
                </div>
              </div>
              <div slot="reference">
                <!-- // 图片 1 视频 2 文档 3 -->
                <!-- <div class="img"></div> -->
                <img v-if="item.typeId === 1" class="img" :src="item.storage" alt="成果图片" />
                <img v-if="item.typeId !== 1" class="img" :src="require(`@/assets/platform/achi-0${item.typeId}.png`)"
                  alt="achi-type" />
              </div>
            </el-popover>
          </div>
          <div class="text">
            <div class="dec"></div>
            <div class="text-c">
              <span class="title">文件名称：</span>
              <!-- <span class="name">{{item.name}}</span> -->
              <!-- :ref="`achi-${item.id}`" -->
              <input :ref="`achi-${item.id}`" class="name" type="text" v-model="item.name"
                @keydown.enter="handle_rename(item)" />
            </div>
          </div>
        </div>
      </div>
      <!-- 分页 -->
      <pageCom :pagination="pagination" @on-pageChange="pageChange" />
    </Dialog>
    <!-- 视频播放 -->
    <el-dialog title="查看视频" :close-on-click-modal="false" :append-to-body="true" v-if="videoDatalist"
      :visible.sync="videoVisible" width="500px" :before-close="onOpenDialog" class="videoShow">
      <!-- <easy-player class="w460 h200" :video-url="videoDatalist.storage" :live="true"></easy-player> -->
      <LivePlayer :hide-snapshot-button="true" class="w460 h250" :video-url="videoDatalist.storage" controls></LivePlayer>

    </el-dialog>
  </div>
</template>

<script>
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
import Vue from "vue";
Vue.use(VueViewer);
import LivePlayer from "@liveqing/liveplayer";

import EasyPlayer from '@easydarwin/easyplayer'
import Dialog from '@/components/common/new-dialog/index-tow.vue'; // 弹框组件
import BigDialog from "@/components/common/new-dialog/index.vue"; // 弹框组件(有遮罩层)
import pageCom from "@/components/common/page-com-hwb"; // 分页组件
import API from "@/api";
export default {
  props: {
    demandSheetInfo: {
      type: Object,
      default: () => { }
    }
  },
  components: {
    Dialog,
    BigDialog,
    pageCom,
    EasyPlayer,
    LivePlayer
  },
  data() {
    return {
      videoDatalist: null, // 存储视频的数据
      videoVisible: true,
      status: 2, // 2、任务报告  1、飞行成果
      dataList: null, // 列表数据
      pagination: { // 分页信息
        pageNum: 1,
        pageSize: 12,
        total: 0,
      },
    }
  },
  methods: {
    onOpenDialog(done) { // 关闭视频弹框
      this.videoDatalist = null
      this.videoVisible = false
      // done && done()
    },
    closeIDsPopup() {  // 关闭自身
      if (this.demandSheetInfo.isDuo) {
        this.$emit('toMultiTask')
      } else {
        this.$emit('closePopup')
      }
    },
    async async_get_list() { // 搜索
      const res = await API.TASK.outcomeData({
        typeId: this.status,
        id: this.demandSheetInfo.id,
        pageNum: this.pagination.pageNum,
        pageSize: this.pagination.pageSize
      })

      res.data.data.forEach(v => v.boolean = false)
      this.dataList = res.data.data
      this.pagination.total = res.data.totalCount
    },
    pageChange(page) { // 翻页操作
      this.pagination.pageNum = page;
      this.async_get_list();
    },
    changeStatus(num) {  // 选择展示任务报告还是飞行成果
      this.status = num
      this.async_get_list()
    },
    handle_review(data) {  // 查看

      data.boolean = false
      if (data.typeId == 1) {
        let img = [data.storage]
        this.$viewerApi({
          images: img,
        });
        // 查看
      } else if (data.typeId == 2) {
        this.videoDatalist = data
        this.videoVisible = true
      }
    },
    getTime(getDate) {
      let date = new Date(getDate);
      let Year = date.getFullYear();
      let Month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let Day = date.getDate();
      let Hours = date.getHours();
      let Minutes = date.getMinutes();
      let Seconds = date.getSeconds();
      let getDay = date.getDay();
      return `${Year}-${Month}-${getDay} ${Hours}:${Minutes}:${Seconds}`
    },
    async handle_download(data) {
      
      // 下载图片
      if (data.typeId === 1) {
      let link = document.createElement('a')
      let url = data.storage
      // 这里是将url转成blob地址，
      fetch(url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
        link.href = URL.createObjectURL(blob)
        console.log(link.href)
        link.download = `${data.name}.png`;
        document.body.appendChild(link)
        link.click()
      })
      }
      // 视频
      if (data.typeId === 2) {
        // let time = JSON.parse(data.videoStartEnd)[0].startTime
        // fetch(data.storage)
        //   .then(res => res.blob())
        //   .then(blob => {
        //     const a = document.createElement("a");
        //     const objectUrl = window.URL.createObjectURL(blob);
        //     a.download = `${this.getTime(time * 1000)}.mp4`;
        //     a.href = objectUrl;
        //     a.click();
        //     window.URL.revokeObjectURL(objectUrl);
        //     a.remove();
        //   })

        let a = document.createElement("a");// 创建a标签
        a.href = data.storage;                  // 设置下载地址
        a.download = `${data.name}.mp4`;            // 设置下载文件名
        document.body.append(a);        // 把标签添加到文档中
        a.click();                      // 执行click(模拟点击下载)事件
        a.remove();
      }
      if (data.typeId === 3) {
        const a = document.createElement("a");
        a.download = data.name;
        a.href = data.storage;
        a.click();
        a.remove();
      }
      if (data.typeId === 4) {
        let res = await API.TASK.subtaskReportExport(data.key);
        const a = document.createElement("a");
        a.href = URL.createObjectURL(res);
        a.download = `${data.name}.doc`;
        a.click();
        a.remove();
      }
    
    },

    handle_before_rename(data) { // 点重命名时
      console.log(data);
      //点击按钮关闭弹框
      data.boolean = false
      console.log(this.$refs[`achi-${data.id}`]);
      this.$refs[`achi-${data.id}`][0].focus();
    },
    async handle_rename(data) {  // 重命名请求
      if (data.name) {
        let res = await API.TASK.taskDataUpdate({
          id: data.id,
          name: data.name
        })
        if (res.status === 1) {
          this.$el_message('修改成功');
          this.async_get_list();
        } else {
          this.$message.error(res.message)
        }
      } else {
        this.$message.warning('名称不能为空')
      }
    },

  },
  mounted() {
    this.async_get_list()
    console.log(this.demandSheetInfo.isSubtasks);
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .el-popper {
    min-width: 10px !important;
    width: 76px !important;
    height: 86px;
    padding: 4px;
    box-sizing: border-box;
  }
}

.cgk {
  .status-select {
    cursor: pointer;
    width: 170px;
    height: 26px;
    margin-bottom: 16px;

    .button {
      background: rgba(0, 0, 0, 0.7);
      color: #94B3C2;
      font-size: 12px;
      text-align: center;
      width: 50%;
      height: 100%;
      line-height: 26px;
    }

    .button-active {
      background-color: #1F4DCB;
    }
  }

  .content {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;

    .one-box {
      width: 330px;
      height: 240px;
      margin-left: 30px;

      .img-box {
        width: 200px;
        margin-left: 65px;
        height: 180px;

        .img {
          width: 100%;
          height: 180px;
        }
      }

      .text {
        position: relative;
        width: 100%;
        margin-top: 10px;
        height: 36px;
        padding: 0 10px 0 30px;
        box-sizing: border-box;
        background: linear-gradient(90deg, rgba(90, 153, 250, 0.52) 0%, rgba(0, 32, 82, 0) 100%);
        border-radius: 8px 0px 0px 0px;

        .dec {

          position: absolute;
          top: 10px;
          left: 8px;
          width: 17px;
          height: 17px;
          background: url('../img/dec.png');
        }

        .text-c {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          .title {
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #AAB7C3;
            line-height: 36px;
          }

          .name {
            height: 100%;
            // font-size: 16px;
            // font-family: D-DIN-Bold, D-DIN;
            // font-weight: bold;
            // color: #FFFFFF;
            // line-height: 36px;
            // height: 36px;
          }

          input {
            // width: 100%;
            font-size: 16px;
            font-weight: 400;
            color: #a8bdd4;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: center;
            padding: 0;
            border: none;
            outline: none;
            // display: flex;
            align-items: center;
          }
        }

      }
    }
  }
}
</style>