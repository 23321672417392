<template >
    <div>
        <Dialog v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)" @on-close="closeIDsPopup" title="预警统计" :left="140" :top="105"
            :width="1700" style="z-index: 1 ">
            <div class=" ">
                <div class="jcsb " :class="flag ? 'w700' : 'wih100'">
                    <div class="jcsb">
                        <div class="warningStatisticsBtn  cp cf"
                            :class="!flag ? 'warningStatisticsActive' : 'warningStatisticsDefault'"
                            @click="changeItem(true)">
                            预警统计</div>
                        <div class="warningStatisticsBtn cp cf"
                            :class="flag ? 'warningStatisticsActive' : 'warningStatisticsDefault'"
                            @click="changeItem(false)">
                            预警详情</div>
                    </div>
                    <div v-show="flag" class="jcsb">
                        <el-date-picker value-format="yyyy-MM-dd" @change="statisticsWarning(null)" clearable
                            class="mr10 select" size="mini" v-model="time" type="daterange" range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期">
                        </el-date-picker>
                        <el-cascader @change="statisticsWarning(null)" size="mini" class="input-orgname"
                            placeholder="请选择所属单位" v-model="organizationId" :options="selectList" :show-all-levels="false"
                            :props="{
                                children: 'child',
                                label: 'name',
                                value: 'id',
                                checkStrictly: true,
                                emitPath: false,
                                empty: false
                            }" clearable>
                        </el-cascader>
                    </div>
                    <div v-show="!flag" class="jcsb">
                        <el-select clearable @change="warningList(1)" class="mr20" size="mini" v-model="keyword"
                            placeholder="全部能力">
                            <el-option v-for="item in aiList" :key="item.key" :label="item.value" :value="item.value">
                            </el-option>
                        </el-select>
                        <el-select clearable @change="warningList(1)" class="mr20" size="mini" v-model="deviceSerial"
                            placeholder="全部设备">
                            <el-option v-for="item in allDeviceList" :key="item.deviceHardId" :label="item.deviceName"
                                :value="item.deviceHardId">
                            </el-option>
                        </el-select>
                        <el-date-picker @change="warningList(1)" value-format="yyyy-MM-dd HH:mm:ss" class="mr20 select"
                            size="mini" v-model="value1" type="daterange" range-separator="至" start-placeholder="开始日期"
                            end-placeholder="结束日期">
                        </el-date-picker>
                        <el-button type="primary" size="mini" @click="selectAll">全选</el-button>
                        <el-button type="primary" size="mini" @click="reset">刷新</el-button>
                        <el-button type="danger" size="mini" @click="deleteFn">删除</el-button>

                    </div>
                </div>
                <div v-show="flag">
                    <div class="approval-info w700 mt10 jcsb state">
                        <!-- 待审批 -->
                        <div @click="approvalStatusClick(0)" class="jc hwb-flex cp  dsp"
                            :class="approvalStatus == 0 ? 'selected' : null" style="color:#50F9F4;">
                            <div class="jc-tp">
                                <img src="./img/dsp.svg" alt="">
                            </div>
                            <div class="jc-text">
                                <div class="num">{{ warningStatistics.total }}</div>
                                <div class="text">预警总数</div>
                            </div>
                        </div>
                        <!-- 审批通过 -->
                        <div @click="approvalStatusClick(1)" class="jc hwb-flex cp sptg"
                            :class="approvalStatus == 1 ? 'selected' : null" style="color:#2ecf81;">
                            <div class="jc-tp">
                                <img src="./img/sptg.svg" alt="">
                            </div>
                            <div class="jc-text">
                                <div class="num">{{ warningStatistics.effect }}</div>
                                <div class="text">有效预警</div>
                            </div>
                        </div>
                        <div @click="approvalStatusClick(2)" class="jc hwb-flex cp  spbtg"
                            :class="approvalStatus == 2 ? 'selected' : null" style="color:#FFD360;">
                            <div class="jc-tp">
                                <img src="./img/spbtg.svg" alt="">
                            </div>
                            <div class="jc-text">
                                <div class="num">{{ warningStatistics.uneffect }}</div>
                                <div class="text">无效预警</div>
                            </div>
                        </div>
                    </div>
                    <div class="wih100  approvalcontent mt10">
                        <div class="cf fw700  jcsb w70">
                            <div style="backgroundColor:#fdc101" class="w3 h16 f19"></div> 预警信息
                        </div>
                        <div ref="warningstatisticshistogram" id="warningstatisticshistogram" class="wih100 h300"></div>
                        <div class="cf fw700  jcsb w70 ">
                            <div style="backgroundColor:#fdc101" class="w3 h16 f19"></div> 数据推送
                        </div>
                        <div class="pr  wih100 h300">
                            <div id="warningstatisticspie" class="w500 h300 pr " style="">

                            </div>
                            <div class="pa top90 left160 cf" style="color:#c6d4e5">
                                <span class="f26 dib mr5">{{ num }}</span> 条
                            </div>
                        </div>

                    </div>
                </div>
                <div v-show="!flag" class=" warningStatisticsBox">
                    <div class="itemBox">
                        <el-image-viewer style="z-index:999" v-if="showViewer" :on-close="closeViewer"
                            :url-list="getScrList(warningListDialogObj)" />
                        <div class="item  h320  " v-for="(item, index) in warningListObj"
                            :class="item.status == true ? 'active_item' : ''" @dblclick='onPreview(index)'
                            @click.stop="handclick(index, item.status)">
                            <div class="h200 wih100 img">
                                <!-- <el-image class="wih100 ht100"  :src="item.scene" :preview-src-list="getScrList(warningListObj)">
                                </el-image> -->
                                <img class="wih100 ht100" :src="item.scene" alt="">
                            </div>
                            <div class="jcsb ml20   mt10 pb10" style="border-bottom: 1px solid #182235;width:80%">
                                <span class=" f16 cf">{{ item.aiName }}</span><span class="f14" style="color:#8792a0">目标数量:
                                    {{ item.num }}</span>
                            </div>
                            <div class=" pl20 pr20 mt10">
                                <span class=" f14 " style="color:#8792a0">设备名称：</span><span class="cf f16">
                                    {{ item.deviceSerial }}
                                </span>
                            </div>
                            <div class=" pl20 pr20 mt10">
                                <span class=" f14 " style="color:#8792a0">预警时间：</span><span class="cf f16">{{ item.date
                                }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="fr tr wih100 mt10 cf">
                        <el-pagination :page-sizes="[8, 30, 300, 500]" class="cpt-table-pagination fr "
                            @size-change="handleSizeChange" @current-change="handleCurrentChange"
                            :current-page.sync="pageNum" :page-size="pageSize" layout="total,sizes, prev, pager, next"
                            :total="total">
                        </el-pagination>
                    </div>

                </div>
            </div>
        </Dialog>
        <!-- onPreview -->
    </div>
</template>
<script>
import Dialog from '../dialog'; // 弹框组件
import * as echarts from "echarts";
import "echarts-gl";
import API from "@/api";
import highcharts from "highcharts";
import { mapGetters } from "vuex"
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
    components: {
        Dialog,
        ElImageViewer
    },
    computed: {
        ...mapGetters(["dbox_data"])
    },
    data() {
        return {
            time: null,
            loading: false,
            showViewer: false,
            pageNum: 1,
            pageSize: 8,
            total: 0,
            options: [{
                value: '选项1',
                label: '黄金糕'
            }, {
                value: '选项2',
                label: '双皮奶'
            }, {
                value: '选项3',
                label: '蚵仔煎'
            }, {
                value: '选项4',
                label: '龙须面'
            }, {
                value: '选项5',
                label: '北京烤鸭'
            }],
            keyword: '',
            deviceSerial: null,
            value1: '',
            num: null,
            approvalStatus: 0,
            // 机构id
            organizationId: null,
            selectList: null,
            warningStatistics: {
                num: 12,
                total: 0,
                effect: 0,
                uneffect: 0,
            },
            exempleData: [],
            warningPositionList: [
                { id: 1, title: "沁园街道" },
                { id: 2, title: "济水街道" },
                { id: 3, title: "北海街道" },
                { id: 4, title: "天坛街道" },
                { id: 5, title: "玉泉街道" },
                { id: 6, title: "克井镇" },
                { id: 7, title: "五龙口镇" },
                { id: 8, title: "轵城镇" },
                { id: 9, title: "承留镇" },
                { id: 10, title: "邵原镇" },
                { id: 11, title: "坡头镇" },
                { id: 12, title: "梨林镇" },
                { id: 17, title: "大峪镇" },
                { id: 14, title: "思礼镇" },
                { id: 15, title: "王屋镇" },
                { id: 16, title: "下冶镇" },
            ],
            flag: true,
            warningListObj: null,
            warningListDialogObj: null,
            aiList: null,
            allDeviceList: null
        }
    },
    async mounted() {
        await this.getAllOrganization()
        await this.warningPushFn()
        this.initOption()
        this.statisticsWarning(null)
        this.warningList()
        this.drd_task_ais()
        this.allDevice()
    },
    methods: {
        onPreview(index) {
            let data = JSON.parse(JSON.stringify(this.warningListObj))
            let qm = data.slice(0, index)
            let hm = data.splice(index)
            let str = hm.concat(qm)
            this.warningListDialogObj = str
            this.showViewer = true
        },
        closeViewer() {
            this.showViewer = false
        },
        getTreeData(data) {
            for (let i = 0; i < data.length; i++) {
                if (data[i].child && data.length < 1) {
                    // children若为空数组，则将children设为undefined
                    data[i].child = undefined;
                } else {
                    // children若不为空数组，则继续 递归调用 本方法
                    if (data[i].child && data[i].child.length < 1) {
                        this.getTreeData(data[i].child);
                    }
                }
            }
            return data;
        },
        getScrList(imglist) {
            return imglist.map(res => res.scene)
        },
        async getAllOrganization() {
            // let { data } = await API.AIRWAY.getAllOrganizationBack();
            let data = this.dbox_data
            this.selectList = this.getTreeData(data);
            // console.log(this.getTreeData(data));
            // console.log(this.selectList.length);
        },
        async warningPushFn() {
            let res = await API.FLIGHTMANAGEMENT.warningPush()
            if (res.code == 200) {
                this.num = res.data.total
                this.exempleData = res.data.organization.map(item => {
                    return {
                        name: item.anotherName,
                        value: item.distCode
                    }
                })
            }
        },
        // 预警信息
        async statisticsWarning(effect) {
            console.log(effect, 'effect');
            let a = null
            if (this.approvalStatus == 0) { } else if (this.approvalStatus == 1) {
                a = 1
            } else if (this.approvalStatus == 2) {
                a = 0
            }
            if (!effect) effect = a
            let startTime = null
            let endTime = null
            if (this.time) {
                startTime = this.time[0]
                endTime = this.time[1]
            }
            let res = await API.FLIGHTMANAGEMENT.statisticsWarning({
                timeType: 'year',
                effect: effect,
                organizationId: this.organizationId || null,
                startTime: startTime,
                endTime: endTime,
            })
            if (res.code == 200) {
                let data = []
                data = res.data.data.map(item => ({
                    name: item.warningTypeName,
                    value: item.warningIsSumTask
                }))
                this.verticalBarChartsConfig(data)
                this.warningStatistics = {
                    total: res.data.total,
                    effect: res.data.effect,
                    uneffect: res.data.uneffect,
                }
            }
        },
        dateFn() {
            this.warningList()
        },
        async allDevice() {
            let res = await API.FLIGHTMANAGEMENT.allDevice()
            if (res.status == 1) {
                this.allDeviceList = res.data
            }
        },
        async deleteFn() {
            let ids = this.warningListObj.filter(item => item.status).map(k => k.id)
            if (ids.length == 0) return this.$message.warning('请选择需要删除的预警信息！')
            let res = await API.FLIGHTMANAGEMENT.multiDelete(ids)
            if (res.status == 1) {
                this.$message.success('删除成功!')
                this.warningList()

            }
        },
        // 全选
        selectAll() {
            let arr = this.warningListObj.map(item => ({
                ...item,
                status: true
            }))
            this.warningListObj = arr
            this.$forceUpdate()
        },
        handclick(index, status) {
            this.warningListObj[index].status = !status
        },
        // 重置
        reset() {
            this.pageNum = 1,
                this.pageSize = 8
            this.deviceSerial = null
            this.keyword = null
            this.value1 = null
            this.warningList()
        },
        // findAIList(item) {
        //     let res = this.aiList.filter(k => k.key == item)
        //     return res[0].value || '暂无'
        // },
        async drd_task_ais() { // AI下拉
            try {
                let res = await API.AI.ailist();
                this.aiList = res.data
            } catch (e) {
                console.error(e);
            }
        },
        async warningList(num) {
            if (num == 1) {
                this.pageNum = 1
            }
            this.loading = true
            let { pageNum, pageSize, keyword, deviceSerial, value1 } = this
            let start = null
            let end = null

            if (value1 && value1.length > 0) {
                start = value1[0]
                end = value1[1]
            }
            let res = await API.FLIGHTMANAGEMENT.warningList({
                page: pageNum,
                pageSize: pageSize,
                keyword: keyword || null,
                deviceSerial: deviceSerial || null,
                start: start,
                end: end
            })
            if (res.status == 1) {
                this.warningListObj = res.data.records.map(item => ({
                    ...item,
                    status: false
                }))
                this.loading = false
                this.total = res.data.total

            }
        },
        // 
        handleSizeChange(val) {
            this.pageSize = val
            this.warningList()
        },
        handleCurrentChange(val) {
            this.pageNum = val
            this.warningList()

        },
        initOption() {
            let dataList = []
            for (let i = 0; i < this.exempleData.length; i++) {
                const element = this.exempleData[i];
                dataList.push({
                    name: element.name,
                    percent: element.value,
                    'h': 0,
                    bfb: 0,
                    y: parseInt(element.value)
                })

            }
            this.dataList = dataList
            // this.dataList = [
            //     {
            //         "name": "DJI御2S",
            //         "y": 2,
            //         "percent": 0.03125,
            //         "h": 0,
            //         "bfb": 0
            //     },
            //     {
            //         "name": "DJI御3",
            //         "y": 4,
            //         "percent": 0.09375,
            //         "h": 0,
            //         "bfb": 0
            //     }
            // ]
            let quantity = 0; // 总数
            this.dataList.forEach((item) => {
                quantity += item.y;
            });
            this.dataList.forEach((item) => {
                item.bfb = parseInt((item.y / quantity) * 100);
                item.h = 40
                // item.h = parseInt(0.86 * item.bfb); // 最高高度60，根据比例渲染高度
                // console.log(this.dataList, "dataList----->>>");
            });
            // 修改3d饼图绘制过程
            var each = highcharts.each,
                round = Math.round,
                cos = Math.cos,
                sin = Math.sin,
                deg2rad = Math.deg2rad;
            highcharts.wrap(
                highcharts.seriesTypes.pie.prototype,
                "translate",
                function (proceed) {
                    proceed.apply(this, [].slice.call(arguments, 1));
                    // Do not do this if the chart is not 3D
                    if (!this.chart.is3d()) {
                        return;
                    }
                    var series = this,
                        chart = series.chart,
                        options = chart.options,
                        seriesOptions = series.options,
                        depth = seriesOptions.depth || 0,
                        options3d = options.chart.options3d,
                        alpha = options3d.alpha,
                        beta = options3d.beta,
                        z = seriesOptions.stacking
                            ? (seriesOptions.stack || 0) * depth
                            : series._i * depth;
                    z += depth / 2;
                    if (seriesOptions.grouping !== false) {
                        z = 0;
                    }
                    each(series.data, function (point) {
                        var shapeArgs = point.shapeArgs,
                            angle;
                        point.shapeType = "arc3d";
                        var ran = point.options.h;
                        shapeArgs.z = z;
                        shapeArgs.depth = depth * 0.75 + ran;
                        shapeArgs.alpha = alpha;
                        shapeArgs.beta = beta;
                        shapeArgs.center = series.center;
                        shapeArgs.ran = ran;
                        angle = (shapeArgs.end + shapeArgs.start) / 2;
                        point.slicedTranslation = {
                            translateX: round(
                                cos(angle) * seriesOptions.slicedOffset * cos(alpha * deg2rad)
                            ),
                            translateY: round(
                                sin(angle) * seriesOptions.slicedOffset * cos(alpha * deg2rad)
                            ),
                        };
                    });
                }
            );
            (function (H) {
                H.wrap(
                    highcharts.SVGRenderer.prototype,
                    "arc3dPath",
                    function (proceed) {
                        // Run original proceed method
                        var ret = proceed.apply(this, [].slice.call(arguments, 1));
                        ret.zTop = (ret.zOut + 0.5) / 100;
                        return ret;
                    }
                );
            })(highcharts);
            highcharts.chart('warningstatisticspie', {
                chart: {
                    animation: true,
                    backgroundColor: "none",
                    type: "pie", //饼图
                    margin: [0, 0, 0, 0],
                    options3d: {
                        enabled: true, //使用3d功能
                        alpha: 10, //延y轴向内的倾斜角度
                        beta: 0,
                    },
                    title: {
                        floating: true,
                        text: '圆心显示的标题'
                    },
                    events: {
                        load: function () {
                            var each = highcharts.each,
                                points = this.series[0].points;
                            each(points, function (p, i) {
                                p.graphic.attr({
                                    translateY: -p.shapeArgs.ran,
                                });
                                p.graphic.side1.attr({
                                    translateY: -p.shapeArgs.ran,
                                });
                                p.graphic.side2.attr({
                                    translateY: -p.shapeArgs.ran,
                                });
                            });
                        },
                    },
                },
                legend: {
                    enabled: true, // 关闭图例
                    align: "right", //水平方向位置
                    verticalAlign: "top", //垂直方向位置
                    layout: "vertical",
                    x: -20,
                    y: 20,
                    itemDistance: 15,
                    symbolWidth: 15,
                    symbolHeight: 15,
                    symbolRadius: 0,
                    // symbolRadius: "2%", // 修改成圆
                    itemMarginBottom: 20,
                    useHTML: true,
                    labelFormat: '&nbsp;&nbsp;&nbsp;&nbsp;{name}&nbsp;&nbsp;&nbsp;&nbsp;{percentage:.1f} %',
                    itemHoverStyle: { color: '#00fff0' },
                    itemStyle: {
                        color: "#fff",
                        fontSize: 30,
                    },
                },
                title: {
                    enabled: false,
                    text: '推送数量总计',
                    align: "left",
                    verticalAlign: "middle",
                    x: 130,
                    y: 10,
                    style: {
                        color: "#fff",
                        fontSize: '16px'
                    },


                },
                subtitle: {
                    floating: true,
                    text: "",
                    align: 'left',
                    x: 180,
                    verticalAlign: "middle",
                    style: {
                        color: '#c0d8fa'
                    }
                },

                plotOptions: {
                    pie: {
                        allowPointSelect: false, // 禁用点击
                        cursor: "pointer",
                        depth: 30,//15
                        showInLegend: true,
                        size: "60%", // 外圈直径大小
                        innerSize: 130, // 内圈直径大小
                        center: ["35%", "50%"],
                        colors: [
                            "#0ecbff",
                            "#d26b00",
                            "#41e0ba",
                            "#e9483b",
                            "rgb(13,145,239)",
                            "rgb(167,67,234)",
                        ],
                        dataLabels: {
                            useHTML: true,
                            enabled: false, //是否显示饼图的线形tip
                            distance: 0,
                            borderColor: '#007acc',
                            align: "center",
                            verticalAlign: 'top',
                            position: "right",
                            format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                            color: "#ffffff",
                            style: {
                                textOutline: "none",
                                fontSize: 14,
                            },
                        },
                    },
                },
                credits: {
                    enabled: false, // 禁用版权信息
                },
                series: [
                    {
                        type: "pie",
                        name: "数量",
                        data: this.dataList,
                    },
                ],
            });
        },
        //筛选出数据中对应键值组成的数组
        getArrByKey(data, k) {
            let key = k || "value";
            let res = [];
            if (data) {
                data.forEach(function (t) {
                    res.push(t[key]);
                });
            }
            return res;
        },
        //给每一项数据加上symbolPosition，即图形相对于原本位置的偏移
        getSymbolData(data, symbol) {
            let arr = [];
            for (var i = 0; i < data.length; i++) {
                arr.push({
                    ...data[i],
                    symbolPosition: symbol ? symbol : 'start',
                })
            }
            return arr;

        },
        verticalBarChartsConfig(data1) {
            let exempleData = [
                { name: '2015', value: 11158 }, { name: '2016', value: 3012 },
                { name: '2017', value: 9449 }, { name: '2018', value: 47 },
                { name: '2019', value: 53 }, { name: '2020', value: 2394 },
                { name: '2021', value: 2823 },
            ];
            let data = data1 || exempleData;
            //最大值
            let maxValue = Math.max.apply(null, this.getArrByKey(data));
            //用于背景半透明柱子渲染
            let bgArr = data.map((item, index) => { return maxValue + maxValue * 0.2; });
            let option = {
                tooltip: {
                    trigger: 'item',
                    backgroundColor: 'rgba(5,28,44,0.9)',
                    borderColor: 'rgba(8,145,166,0.7)',
                    borderWidth: 1,
                    padding: [10, 10],
                    axisPointer: {
                        type: 'line',
                        lineStyle: {
                            color: 'rgba(255,255,255,0.3)',
                            width: 24,
                            cap: 'round'
                        }
                    },
                    textStyle: {
                        fontSize: 14
                    },
                    formatter: function (data) {
                        return `<p class="tool-title">${data.name} <span style="font-size: 18px;font-family:BigNum;background-clip:text;-webkit-background-clip:text;-webkit-text-fill-color:transparent;background-image:-webkit-linear-gradient(top,rgb(2,196,252) 40%,rgb(5,55,150));line-height: 18px;">${data.value}</span></p>`

                    }
                },
                //         legend: {
                //   enabled: true, // 关闭图例
                //   align: "right", //水平方向位置
                //   verticalAlign: "top", //垂直方向位置
                //   layout: "vertical",
                //   x: fontChart(-20),
                //   y: fontChart(30),
                //   symbolWidth: fontChart(10),
                //   symbolHeight: fontChart(10),
                //   symbolRadius: "50%", // 修改成圆
                //   itemMarginBottom: fontChart(8),
                //   useHTML: true,
                //   //labelFormat: '{name}&nbsp;&nbsp;&nbsp;&nbsp;{y}',
                //   labelFormatter: function () {
                //     return (
                //       '<div style="width: .3125rem;display: inline-block">' +
                //       this.name +
                //       ':&nbsp;&nbsp;</div><div style="color: #00d7da;display: inline-block">' +
                //       this.y +
                //       "</div>"
                //     );
                //   },
                //   itemStyle: {
                //     color: "#f4f4f6",
                //     fontSize: fontChart(12),
                //   },
                // },
                legend: {
                    show: true,
                    itemWidth: 30,
                    align: "left", //水平方向位置
                    itemHeight: 12,
                    itemGap: 30,
                    textStyle: {
                        fontSize: 15,
                        color: 'rgba(255,255,255,0)'
                    },
                },
                grid: {
                    left: '5%',
                    right: '5%',
                    bottom: '15%',
                    top: '18%',
                    containLabel: true
                },
                xAxis: {
                    name: '',
                    nameTextStyle: {
                        color: '#fff',
                        align: 'left',
                        fontSize: 14,
                        fontFamily: '优设标题黑'
                    },
                    axisLine: { show: true, lineStyle: { color: 'rgba(255,255,255,0.1)' } },
                    axisLabel: { show: true, textStyle: { color: 'rgb(255, 255, 255)', } },
                    axisTick: { show: false },
                    splitLine: { show: false, lineStyle: { color: 'rgba(73, 163, 201,0.1)' } },
                    data: this.getArrByKey(data, 'name'),
                },
                yAxis: {
                    // name: '人数（万）',
                    nameTextStyle: {
                        color: 'rgb(193, 205, 214)',
                        fontSize: 15,
                        align: 'middle',
                        lineHeight: 14,
                        fontFamily: '优设标题黑'
                    },
                    axisLine: { show: false, lineStyle: { color: 'rgba(174, 193, 227,0.1)' } },
                    axisLabel: { show: true, textStyle: { color: 'rgb(174, 193, 227)', } },
                    axisTick: { show: false },
                    splitLine: { show: true, lineStyle: { color: 'rgba(174, 193, 227,0.1)' } },
                },
                series: [
                    //下半截柱状图
                    {
                        type: 'bar',
                        barWidth: 20,
                        barGap: '-100%',
                        itemStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1,
                                    [{
                                        offset: 0,
                                        color: "rgba(82, 183, 221,0)"
                                    },
                                    {
                                        offset: 1,
                                        color: "#ff004d"
                                    }
                                    ],
                                    false
                                )
                            }
                        },
                        data: this.getSymbolData(data)
                    },
                    //头部菱形
                    // {
                    //     "type": "pictorialBar",  
                    //     "symbol": 'diamond',
                    //     "symbolPosition": "end",
                    //     "symbolSize": [20, 10],
                    //     "symbolOffset": [0, -5],
                    //     "z": 12,
                    //     "itemStyle": {
                    //         "normal": {
                    //             color: new echarts.graphic.LinearGradient(0, 0, 0, 1,
                    //                 [{
                    //                     offset: 0,
                    //                     color: "rgba(146,150,154,.2)"
                    //                 },
                    //                 {
                    //                     offset: 1,
                    //                     color: "rgba(82, 183, 221,.5)"
                    //                 }
                    //                 ],
                    //                 false
                    //             ),
                    //         }
                    //     },
                    //     "data": bgArr
                    // },
                    //中间菱形
                    {
                        "name": "",
                        "type": "pictorialBar",
                        "symbol": 'diamond',
                        "symbolSize": [20, 10],
                        "symbolOffset": [0, -5],
                        "symbolPosition": "end",
                        "itemStyle": {
                            "normal": {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1,
                                    [{
                                        offset: 0,
                                        color: "rgba(82, 183, 221,1)"
                                    },
                                    {
                                        offset: 1,
                                        color: "rgba(82, 183, 221,1)"
                                    }
                                    ],
                                    false
                                ),
                            }
                        },
                        "z": 12,
                        "data": this.getArrByKey(data, 'value')
                    },
                    {
                        name: '2019',
                        type: 'bar',
                        barWidth: 20,
                        // barGap: '-100%',    
                        stack: '柱状图高度',
                        "itemStyle": {
                            "normal": {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 0,
                                    [{
                                        offset: 0,
                                        color: "#ff004d"
                                    },
                                    {
                                        offset: 1,
                                        color: "rgba(146,150,154,.1)"
                                    }
                                    ],
                                    false
                                ),
                            }
                        },
                        data: bgArr
                    }
                ]
            };

            setTimeout(() => {
                var container = this.$refs.warningstatisticshistogram
                /*初始化echarts 容器对象
                        init函数中参数
                */
                var myCharts = echarts.init(container);
                myCharts.setOption(option);
                // return option;
            }, 1000);


        },
        changeItem(flag) {
            this.flag = flag
        },
        approvalStatusClick(num) {
            this.approvalStatus = num
            if (num == 0) {
                this.statisticsWarning(null)
            } else if (num == 1) {
                this.statisticsWarning(1)
            } else if (num == 2) {

                this.statisticsWarning(0)
            }
        },
        closeIDsPopup() {
            this.$emit('closeDemand');
        },
        create() {
        },
    },
}
</script>
<style lang="scss" scoped>
.warningStatisticsBox {
    height: 75vh;


    .itemBox {
        display: flex;
        flex-wrap: wrap;
        overflow-y: auto;
        height: 71.4vh;
    }

    .item {
        width: 24%;
        height: 344px;
        margin: 5px;
        // background: rgba(0, 0, 0, 0.4);
        box-shadow: inset 0px 0px 30px 0px rgba(26, 79, 255, 0.82);
        // border: 1px solid #73A6D1;
        backdrop-filter: blur(4px);
        border: 1px solid transparent;

        .img {
            background-color: #0b3d66;
        }
    }

    .item:hover {
        border: 1px solid #73A6D1;
    }

    .active_item {
        border: 1px solid #73A6D1;
    }


}

.warningStatisticsBtn {
    height: 32px;
    line-height: 32px;
    padding: 1px 5px;
    font-size: 14px;
    margin-right: 10px;
    cursor: pointer;
    color: #fff;
}

.cpt-table-pagination {
    height: 50px;
    display: flex;

    // align-items: center;
    ::v-deep {
        button {
            background: transparent;
            width: 40px;
            height: 40px;
            box-sizing: border-box;
            border: 1px solid #B6D4FF;
            font-size: 18px;
            letter-spacing: 0;
            text-align: center;
            font-weight: 400;
            border-radius: 4px;
            line-height: 40px;
            margin-right: 8px;
            box-sizing: border-box;
            color: #9fc4ff;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;

            &:disabled {
                background: transparent;
                color: #889fb2;
                opacity: 0.3;
            }
        }

        .el-pagination__total {
            font-size: 18px;
            color: #93b6f0;
            letter-spacing: 0;
            text-align: center;
            font-weight: 400;

            text-align: center;
            height: 40px;
            line-height: 40px;
            display: inline-block;
            margin: 0px;
        }

        .el-pagination__sizes {
            height: 40px;
            line-height: 40px;
            margin: 0px;
            box-sizing: border-box;
            border: 1px solid #B6D4FF;
            border-radius: 4px;
            margin: 0 6px;

            .el-select {
                .el-input {
                    width: 100px;
                    padding-right: 20px;
                    margin: 0;

                    .el-input__inner {
                        background: transparent;
                        border: none;
                        font-size: 18px;
                        color: #93b6f0;
                        text-align: center;
                        font-weight: 400;
                        padding: 0px;
                        height: 40px;
                    }

                    .el-input__suffix {
                        .el-input__suffix-inner {
                            .el-icon-arrow-up {
                                line-height: 42px;
                            }
                        }
                    }
                }
            }
        }

        .el-pager {
            height: 30px;

            .number {
                background: transparent;
                font-size: 12px;
                color: #9fc4ff;
                text-align: center;
                font-weight: lighter;
                width: 40px;
                height: 40px;
                box-sizing: border-box;
                border: 1px solid #B6D4FF;
                font-size: 18px;
                letter-spacing: 0;
                text-align: center;
                border-radius: 4px;
                line-height: 40px;
                margin-right: 8px;
                box-sizing: border-box;

                &.active {
                    opacity: 0.8;
                    background-image: linear-gradient(180deg,
                            rgba(44, 123, 255, 0.5) 0%,
                            rgba(72, 140, 255, 0) 100%);
                    color: #ffffff;
                }
            }

            .more {
                background: transparent;
                line-height: 40px;
                width: 40px;
                height: 40px;

                box-sizing: border-box;
                border: 1px solid #B6D4FF;
                border-radius: 4px;
                margin-right: 8px;
                font-size: 20px;
                color: #93b6f0;
            }
        }

        .el-pagination__jump {
            font-size: 18px;
            color: #90b2ec;
            letter-spacing: 0;
            text-align: center;
            font-weight: 400;
            height: 40px;
            height: 40px;
            margin-left: 6px;
            line-height: 40px;
            display: flex;
            align-items: center;

            .el-input {
                box-sizing: border-box;
                height: 100%;

                .el-input__inner {
                    border: 1px solid #B6D4FF;
                    border-radius: 4px;
                    background: transparent;
                    height: 100%;
                    box-sizing: border-box;
                    padding: 0;

                    font-size: 18px;
                    color: #90b2ec;
                    letter-spacing: 0;
                    text-align: center;
                    font-weight: 400;
                }
            }
        }
    }

    &.mini {
        height: 100%;
        display: flex;
        align-items: center;

        ::v-deep {
            button {
                height: 20px;
                width: 20px;
                line-height: 20px;
                background: transparent;
                border: none;
                color: #4d7592;
                min-width: auto;

                &.btn-next {
                    margin-left: 8px;
                }
            }

            .el-pagination__total {
                font-size: 14px;
                color: #4d7592;
            }

            .el-pagination__sizes {
                display: none;
            }

            .el-pager {
                height: 30px;

                .number {
                    height: 100%;
                    width: auto;
                    line-height: 20px;
                    background: transparent;
                    border: none;
                    font-size: 14px;
                    color: #4d7592;
                    letter-spacing: 0;
                    text-align: center;
                    font-weight: 500;
                    min-width: 20px;
                    margin-right: 0;

                    &.active {
                        opacity: 1;
                        color: #0099ff;
                    }
                }

                .more {
                    height: 100%;
                    width: 20px;
                    line-height: 20px;
                    font-size: 12px;
                    border: none;
                    min-width: auto;
                    margin-right: 0px;

                    &::before {
                        line-height: 20px;
                    }
                }
            }

            .el-pagination__jump {
                height: 100%;
                font-size: 14px;
                color: #4d7592;
                letter-spacing: 0;
                text-align: center;
                font-weight: 400;

                .el-input {
                    .el-input__inner {
                        border: 1px solid #B6D4FF;
                        border-radius: 2px;
                        width: auto;
                        min-width: 20px;
                        font-size: 14px;
                        color: #4d7592;
                        letter-spacing: 0;
                        text-align: center;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}

.approvalcontent {
    overflow-y: auto;
    height: 60vh;
    overflow-x: hidden;
}

.warningStatisticsDefault {
    background: #1F4DCB;

}

.warningStatisticsActive {
    background: #010711;
}

.approval-info {
    width: 100%;
    height: 109px;

    .dsp {
        background-image: url(./img/1.png);

        &.selected {
            background-image: url(~@/assets/img/click-3.png);
        }
    }

    .sptg {
        background-image: url(./img/3.png);

        &.selected {
            background-image: url(~@/assets/img/click-4.png);
        }
    }

    .spbtg {
        background-image: url(./img/4.png);

        &.selected {
            background-image: url(~@/assets/img/click-2.png);
        }
    }

    .jc {
        width: 204px;
        height: 109px;

        .jc-tp {
            width: 46px;
            height: 46px;
            font-size: 46px;
            margin-left: 20px;

            &.iconfont.icon-leijifeihangjiaci {
                background: linear-gradient(180deg, #FFFFFF 0%, #D0FDFC 59%, #50F9F4 100%);
                //将字体图标改为只显示背景在字体上，就完成了字体的渐变
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            &.iconfont.icon-leijifeihangshichang {
                background: linear-gradient(180deg, #CBF3FF 0%, #9BE8FF 59%, #00C4FF 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            &.iconfont.icon-leijifeihangjuli {
                background: linear-gradient(180deg, #EFFFF5 0%, #82FFD0 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            &.iconfont.icon-leijirenwushuliang {
                background: linear-gradient(180deg, #FFF0C8 0%, #FFCE4F 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        .jc-text {
            text-align: center;
            width: 120px;

            .num {
                font-size: 32px;
                font-family: DINAlternate-Bold, DINAlternate;
            }

            .text {
                font-size: 16px;
                font-family: MicrosoftYaHei;
                color: #DAE4FF;
            }
        }
    }
}

.select {

    // width: 730px;
    ::v-deep {
        .el-input__inner {
            background-color: #000;
            border: 0;
            // line-height: 30px;
            color: #fff;
        }

        .el-range__icon {
            line-height: 26px;
        }

        .el-range__close-icon {
            line-height: 26px;
        }

        .el-range-separator {
            line-height: 24px;
            color: #fff;
        }
    }

    .button {
        margin-right: 10px;
        width: 210px;
    }

    .button-date {
        margin-right: 10px;
        width: 380px;
    }

    .search-button {
        box-sizing: border-box;
        display: inline-block;
        width: 86px;
        height: 30px;
        background: rgba(22, 44, 154, 0.82);
        border-radius: 2px;
        border: 1px solid;
        border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        line-height: 30px;
        color: #FFFFFF;

    }

    .search-button:hover {
        color: #B6D4FF;
        background: rgba(38, 71, 238, 0.61);
    }
}
</style>
<style lang="scss">
.el-cascader-menu__list {

    background: #181f65;
    border: 1px solid #009aff;

}

// #1d57bc
.el-cascader-node__label {
    color: #fff
}

.el-cascader-node:not(.is-disabled):focus,
.el-cascader-node:not(.is-disabled):hover {
    background: #1d57bc;

}
</style>