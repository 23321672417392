<template>
  <div>
    <!-- 飞行成果需求单列表 -->
    <Dialog v-show="type === 1"  class="cgk" @on-close="closeIDsPopup" title="成果管理" :width="1490" :top="120" :left="120">
      <!-- 赛选栏 -->
      <div class="select hwb-flex">
        <el-date-picker
          class="button-date"
          v-model="edit_form.date"
          type="daterange"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          size="mini"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
        <el-cascader
          popper-class="input-orgname"
          placeholder="请选择所属机构"
          v-model="edit_form.organizationId"
          clearable
          size="mini"

          :options="allOrganizationselectList"
          :show-all-levels="false"
          :props="{
            children: 'child',
            label: 'anotherName',
            value: 'id',
            checkStrictly: true,
            emitPath: false,
            empty: false
          }"
          class="button"
        >
        </el-cascader>
        
        <el-input class="button"   size="mini"    v-model="edit_form.name" placeholder="请输入需求单名称" clearable></el-input>
        <div class="search-button cp" @click="async_get_list('search')" >搜索</div>
      </div>
      <!-- 内容 -->
      <div class="content">
        <!-- 一条需求单样式 -->
        <div class="one-box" v-for="(item, index) in dataList" :key="index">
          <div class="img" @click="openAchievementLibrary(item)"></div>
          <div class="text">
            <div class="dec"></div>
            <div class="text-c">
              <span class="title">需求单名称：</span>
              <span class="name">{{item.taskTitle}}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 分页 -->
      <pageCom :pagination="pagination" @on-pageChange="pageChange"/>
    </Dialog>
    <!-- 需求单有子任务点击进入的二级赛选子任务弹框 -->
    <MultiTask :demandSheetInfo="demandSheetInfo" v-if="demandSheetInfo" v-show="type === 2" @toAchievementLibrary="toAchievementLibrary" @closePopup="toIndex"></MultiTask>
    <!-- 飞行成果弹框 -->
    <AchievementLibrary :demandSheetInfo="demandSheetInfo2" v-if="demandSheetInfo2" v-show="type === 3" @toMultiTask="toMultiTask" @closePopup="toIndex"></AchievementLibrary>
  </div>
</template>

<script>
import MultiTask from './multitask.vue'; // 需求单有子任务点击进入的二级赛选子任务弹框
import AchievementLibrary from './achievementLibrary.vue'; // 需求单无子任务点击直接进入飞行成果弹框

import Dialog from '@/components/common/new-dialog/index-tow.vue'; // 弹框组件
import BigDialog from "@/components/common/new-dialog/index.vue"; // 弹框组件(有遮罩层)
import pageCom from "@/components/common/page-com-hwb"; // 分页组件
import API from "@/api";
import getgetAllOrganization from "@/api/mechanismPullDown.js"; // 获取机构下拉js
import {mapGetters} from "vuex"
export default {
  components: {
    Dialog,
    BigDialog,
    pageCom,
    MultiTask,
    AchievementLibrary
  },
  data() {
    return {
      type: 1, // 控制到底展示哪个弹框    1、需求单列表  2、子任务列表  3、飞行成果
      demandSheetInfo:null, // 点击的是哪个需求单，把信息存进来    多任务
      demandSheetInfo2:null, // 点击的是哪个需求单，把信息存进来   单任务
      allOrganizationselectList: null,  // 机构下拉数据
      dataList: null, // 列表数据
      edit_form: { // 搜索字段存储
        date: null,
        organizationId: null,
        name: '',
      },
      pagination: { // 分页信息
        pageNum: 1,
        pageSize: 12,
        total: 0,
        page:1
      },
    }
  },
  computed: {
    ...mapGetters(["dbox_data"]),
  },
  methods: {
    ...getgetAllOrganization,
    closeIDsPopup() {  // 关闭自身
      this.$emit('closeDemand')
    },
    async async_get_list(state) { // 搜索
    console.log(state);
    if(state == 'search') {
      this.pagination.pageNum = 1;
      this.pagination.page = 1;
      console.log(this.pagination);
    }
      const {date,name} = this.edit_form
      let startTime, endTime
      if(date){
        startTime = date[0]
        endTime = date[1]
      } else {
        startTime = null
        endTime = null
      }
      const res = await API.TASK.resultDemandList({
        ...this.edit_form,
        name: name?name : null,
        startTime,
        endTime,
        pageNum:this.pagination.pageNum,
        pageSize: this.pagination.pageSize
      })
      this.dataList = res.data.data
      this.pagination.total = res.data.totalCount
    },
    pageChange(page){ // 翻页操作
      this.pagination.pageNum = page;
      this.async_get_list();
    },
    toAchievementLibrary(item){ // 多任务需求子任务跳到飞行成果
      this.demandSheetInfo2 = item
      this.type = 3
    },
    toMultiTask() {
      this.demandSheetInfo2 = null
      this.type = 2
    },
    toIndex() { // 关闭其他弹框回到成果管理弹框
      this.type = 1
      this.demandSheetInfo = null
      this.demandSheetInfo2 = null
    },
    openAchievementLibrary(item){ // 有子任务的打开子任务弹框  没有的话直接打开飞行成果
      if(item.child && item.child.length>0){
        this.demandSheetInfo = item
        this.type = 2
      } else{
        this.demandSheetInfo2 = item
        this.type = 3
      }
    }

  },
 async mounted() {
  await this.getAllOrganization()
   if(this.$route.params.taskName) { //是否从需求单制定跳转 , 是则改变搜索条件
     this.edit_form.name = this.$route.params.taskName
   }
   await this.async_get_list()
   if(this.$route.params.taskName) this.openAchievementLibrary(this.dataList[0])
  },
  beforeDestroy(){
    if(this.$route.params.taskName) this.$route.params.taskName = null
  }
};
</script>

<style lang="scss" scoped>
.cgk{
  .select{
    margin: 0px 0 14px 0px;
    width: 730px;
    ::v-deep{
      .el-input__inner{
        background-color: #000;
        border: 0;
        // line-height: 30px;
        color: #fff;
      }
      .el-range__icon{
        line-height: 26px;
      }
      .el-range__close-icon{
        line-height: 26px;
      }
      .el-range-separator{
        line-height: 24px;
        color: #fff;
      }
    }
    .button {
      margin-right: 10px;
      width: 210px;
    }
    .button-date{
      margin-right: 10px;
      width: 380px;
    }
    .search-button{
      box-sizing: border-box;
      display: inline-block;
      width: 86px;
      height: 30px;
      background: rgba(22,44,154,0.82);
      border-radius: 2px;
      border: 1px solid;
      border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      line-height: 30px;
      color: #FFFFFF;

    }
    .search-button:hover{
      color: #B6D4FF;
      background: rgba(38,71,238,0.61);
    }
  }
  .content{
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    .one-box{
      width: 330px;
      height: 240px;
      margin-left: 30px;
      .img{
        width: 100%;
        height: 180px;
        background: url('../img/xqd.png') no-repeat center center;
      }
      .text{
        position: relative;
        width: 100%;
        margin-top: 10px;
        height: 36px;
        padding: 0 10px 0 30px;
        box-sizing: border-box;
        background: linear-gradient(90deg, rgba(90,153,250,0.52) 0%, rgba(0,32,82,0) 100%);
        border-radius: 8px 0px 0px 0px;
        
        .dec{
          
          position: absolute;
          top: 10px;
          left: 8px;
          width: 17px;
          height: 17px;
          background: url('../img/dec.png');
        }
        .text-c{
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          .title{
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #AAB7C3;
            line-height: 36px;
          }
          .name{
            font-size: 16px;
            font-family: D-DIN-Bold, D-DIN;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 36px;
            height: 36px;
          }
        }
        
      }
    }
  }
}
</style>