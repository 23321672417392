<template>
  <div class="personnel_userlistBox">
    <div class="tl">
      <div class="button dib tc lh40 cp cf" @click="showDialogVisible(1)">
        创建用户
      </div>
      <div
        class="button tc dib ml20 lh40 cp cf"
        @click="$message.success('通知成功')"
      >
        一键通知
      </div>
    </div>
    <div>
      <!-- personnel_userlistBoxTable -->
      <el-table
        height="280"
        :data="userList"
        class="mt20 hwb-table-new"
        :header-row-style="{
          'background-color': 'rgba(138,152,177,0.28)',
          color: '#fff',
          'font-size': '14px',
        }"
        :header-row-class-name="'header-row-class-name'"
        :max-height="550"
        :row-class-name="tableRowClassName"
        row-key="id"
        :tree-props="{ children: 'child', hasChildren: 'hasChildren' }"
        :cell-style="{ textAlign: 'center' }"
        :highlight-current-row="false"
        :header-cell-style="{ textAlign: 'center' }"
      >
        <el-table-column
          type="index"
          label="序号"
          :width="50"
        ></el-table-column>
        <el-table-column prop="name" label="用户名称"></el-table-column>
        <el-table-column
          prop="organizationName"
          label="所属单位"
        ></el-table-column>
        <el-table-column
          prop="mobile"
          :width="200"
          label="手机号"
        ></el-table-column>
        <el-table-column prop="status" label="报备状态">
          <template slot-scope="scope">
            <div
              :style="{ color: scope.row.status == '已报备' ? 'green' : 'red' }"
            >
              {{ scope.row.status }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="loginTime" :width="170" label="报备时间">
        </el-table-column>
        <el-table-column prop="action" label="操作" width="180">
          <template slot-scope="scope">
            <span
              class="iconfont icon-genggai cp"
              @click="showDialogVisible(scope.row)"
              style="color: #35d9e7"
            ></span>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog
      :append-to-body="true"
      :title="title"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div class="demo-ruleFormBox">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="用户名称" prop="name">
            <el-input
              placeholder="请输入用户名称"
              v-model="ruleForm.name"
            ></el-input>
          </el-form-item>
          <el-form-item label="用户账号" prop="username">
            <el-input
              placeholder="请输入用户账号"
              v-model="ruleForm.username"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号码" prop="phone">
            <el-input
              placeholder="请输入手机号码"
              v-model="ruleForm.phone"
            ></el-input>
          </el-form-item>

          <el-form-item label="所属机构" prop="organizationId">
            <!-- <el-input
            v-model="ruleForm.organizationName"
            v-if="state"
            :disabled="Boolean(state)"
          ></el-input> -->
            <el-cascader
              v-model="ruleForm.organizationId"
              :options="options"
              clearable
              :showAllLevels="false"
              placeholder="请选择所属机构"
              :props="{
                value: 'id',
                label: 'anotherName',
                children: 'child',
                emitPath: false,
                checkStrictly: true,
              }"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="所属角色" prop="mmcRoleId">
            <el-select
              v-model="ruleForm.mmcRoleId"
              placeholder="请选择所属角色"
            >
              <el-option
                v-for="item in roleList"
                :key="item.id"
                :label="item.roleName"
                :value="item.roleId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="行政区域">
            <el-input type="textarea" v-model="ruleForm.distCode"></el-input>
          </el-form-item> -->
          <el-form-item label="行政区域" prop="destination">
            <el-cascader
              class="input-orgname"
              placeholder="请选择行政区域"
              v-model="ruleForm.distCode"
              :options="cityType"
              :props="{
                children: 'children',
                label: 'title',
                value: 'id',
                emitPath: false,
                empty: false,
              }"
              clearable
            >
            </el-cascader>
          </el-form-item>

          <el-form-item label="机构管理员">
            <el-switch
              v-model="ruleForm.orgAdmin"
              :active-value="1"
              :inactive-value="0"
            ></el-switch>
            <span class="ml20 mr20" style="color: #a1adba">是否飞手</span
            ><el-switch
              v-model="ruleForm.isPilot"
              :active-value="1"
              :inactive-value="0"
            ></el-switch>
            <span class="ml20 mr20" style="color: #a1adba"
              >是否取得相成警航证</span
            >
            <el-switch
              v-model="ruleForm.isPoliceFlightCertificate"
              :active-value="1"
              :inactive-value="0"
            ></el-switch>
          </el-form-item>
          <el-form-item label="用户身份类型" prop="resource">
            <el-radio-group v-model="ruleForm.type">
              <el-radio :label="0">民警</el-radio>
              <el-radio :label="1">辅警</el-radio>
              <el-radio :label="2">飞行助手</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="登录密码" prop="password">
            <el-input
              @focus="ruleForm.password = null"
              placeholder="请输入登录密码"
              v-model="ruleForm.password"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注说明" prop="remark">
            <el-input
              placeholder="请输入备注说明"
              type="textarea"
              v-model="ruleForm.remark"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >提交</el-button
            >
            <el-button @click="resetForm('ruleForm')">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import API from "@/api";
export default {
  data() {
    let phoneNumber = (rule, value, callback) => {
      console.log(value);
      let phone = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!value) {
        callback(new Error("请输入手机号码"));
      } else if (!phone.test(value)) {
        callback(new Error("请输入正确的手机号码"));
      } else {
        callback();
      }
    };
    return {
      title: "创建用户",
      // 用户列表
      userList: [],
      roleList: [],
      options: [],
      ruleForm: {
        name: "",
        username: "",
        remark: null,
        type: null,
        isPilot: null,
        orgAdmin: null,
        isPoliceFlightCertificate: null,
        phone: null,
        password: null,
        distCode: null,
        organizationId: null,
        mmcRoleId: null,
      },
      rules: {
        name: [
          { required: true, message: "请输入用户名称", trigger: "blur" },
          {
            min: 2,
            max: 10,
            message: "长度在 2 到 10 个字符",
            trigger: "blur",
          },
        ],
        username: [
          { required: true, message: "请输入用户账号", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          { min: 11, max: 11, message: "长度为11位", trigger: "blur" },
          { validator: phoneNumber, trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        type: [
          {
            type: "array",
            required: true,
            message: "请选择用户身份类型",
            trigger: "change",
          },
        ],
        distCode: [
          {
            type: "array",
            required: true,
            message: "请输入行政区域编码",
            trigger: "change",
          },
        ],
        organizationId: [
          { required: true, message: "请选择所属机构", trigger: "change" },
        ],
        mmcRoleId: [
          { required: true, message: "请选择所属角色", trigger: "change" },
        ],
      },
      dialogVisible: false,
      pagination: {
        page: 1,
        pageSize: 5,
        total: 0,
      },
      dataSource: [],
      cityType: [
        {
          id: 13,
          title: "济源市",
          children: [
            { id: 1, title: "沁园街道" },
            { id: 2, title: "济水街道" },
            { id: 3, title: "北海街道" },
            { id: 4, title: "天坛街道" },
            { id: 5, title: "玉泉街道" },
            { id: 6, title: "克井镇" },
            { id: 7, title: "五龙口镇" },
            { id: 8, title: "轵城镇" },
            { id: 9, title: "承留镇" },
            { id: 10, title: "邵原镇" },
            { id: 11, title: "坡头镇" },
            { id: 12, title: "梨林镇" },
            { id: 17, title: "大峪镇" },
            { id: 14, title: "思礼镇" },
            { id: 15, title: "王屋镇" },
            { id: 16, title: "下冶镇" },
          ],
        },
      ],
    };
  },
  created() {
    this.roleListFn();
    this.init();
    this.getAllOrganizationWebFn();
  },
  methods: {
    async getAllOrganizationWebFn() {
      let res = await API.FLIGHTMANAGEMENT.getAllOrganization();
      console.log(res);
      this.options = res.data;
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.ruleForm.password == "******") {
            delete this.ruleForm.password;
          }
          if (this.title == "创建用户") {
            let res = await API.FLIGHTMANAGEMENT.registerUser(this.ruleForm);
            if (res.status == 1) {
              this.$message.success("注册成功");
              this.dialogVisible = false;
              this.$refs["ruleForm"].resetFields();
              this.init();
            }
          } else {
            console.log(this.ruleForm, "this.ruleForm");
            let res = await API.FLIGHTMANAGEMENT.modifyInfo(this.ruleForm);
            if (res.status == 1) {
              this.$message.success("修改成功");
              this.dialogVisible = false;
              this.$refs["ruleForm"].resetFields();
              this.init();
            }
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      this.$refs["ruleForm"].resetFields();
      this.ruleForm = {
        id: this.ruleForm.id || null,
        name: "",
        username: "",
        type: null,
        isPilot: null,
        orgAdmin: null,
        isPoliceFlightCertificate: null,
        phone: null,
        password: null,
        distCode: null,
        organizationId: null,
        mmcRoleId: null,
        remark: null,
      };
    },
    async roleListFn() {
      let res = await API.FLIGHTMANAGEMENT.roleList();
      this.roleList = res.data.records;
    },
    async init() {
      // flightManagement
      let res = await API.FLIGHTMANAGEMENT.userLoginList({
        pageNo: 1,
        pageSize: 5000,
      });
      if (res.code == 200) {
        this.userList = res.data.records;
      }
    },
    async showDialogVisible(row) {
      if (row !== 1) {
        // let res = await API.FLIGHTMANAGEMENT.selectLikeList({
        //   phone: row.mobile
        // })
        let res = await API.FLIGHTMANAGEMENT.selectLikeList(row.id);
        console.log(res.data);
        if (res.status == 1) {
          for (const key in this.ruleForm) {
            this.ruleForm[key] = res.data[key];
          }
          this.ruleForm.password = "******";
          this.ruleForm.organizationId = String(this.ruleForm.organizationId);
          this.ruleForm.id = res.data.id;
        }
        this.title = "修改用户";
      } else {
        this.title = "创建用户";
        this.ruleForm = {
          name: "",
          username: "",
          type: null,
          isPilot: null,
          orgAdmin: null,
          isPoliceFlightCertificate: null,
          phone: null,
          password: null,
          distCode: null,
          organizationId: null,
          mmcRoleId: null,
        };
        if (this.$refs["ruleForm"]) {
          this.$refs["ruleForm"].resetFields();
        }
      }
      this.dialogVisible = true;
    },
    handleClose(done) {
      if (this.$refs["ruleForm"]) {
        this.$refs["ruleForm"].resetFields();
      }
      done();
    },
    tableRowClassName({ row, rowIndex }) {
      // 控制任务状态
      if (rowIndex % 2 == 0) {
        return "dan-row";
      } else if (rowIndex % 2 == 1) {
        return "shuang-row";
      }
      return "";
    },
  },
};
</script>
<style lang="scss">
.el-form-item__label {
  // width: 120px !important;
  color: #a1adba !important;
}

.el-radio__label {
  color: #a1adba;
}

.el-input__inner {
  background: rgba(21, 95, 192, 0.1) !important;
  color: #a1adba !important;
}

//  .el-input__inner::placeholder {
//       }

.el-textarea__inner {
  background: rgba(21, 95, 192, 0.1) !important;
  color: #a1adba !important;
}

// .personnel_userlistBox {
//   .aaa{
//     width:100px
//   }
//   .el-form-item__label {
//     width: 120px !important;
//     color: #a1adba !important
//   }
//   .el-form-item__content{
//     margin-left: 120px !important;
// }
// }
</style>
<style lang="scss" scoped>
.personnel_userlistBox {
  .personnel_userlistBoxTable {
    height: 30vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.demo-ruleFormBox {
  height: 60vh;
  overflow-y: auto;
}

.button {
  width: 126px;
  height: 40px;
  background: rgba(38, 71, 238, 0.71);
  box-shadow: inset 0px 1px 3px 0px #ffffff,
    inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
  border-radius: 6px;
  border: 1px solid;
  // border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
}
</style>
