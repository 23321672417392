<template>
  <div class="Airspace_Box">
    <Dialog
      :create="true"
      @on-close="closeIDsPopup"
      title="空域管理"
      :left="140"
      :top="105"
      :width="538"
      @create="create"
    >
      <div class="Airspace">
        <div class="Airspace_Body">
          <div class="Body_Item" v-for="item in dataVal" :key="item.id">
            <div class="Item_Text">
              <span class="item_val">空域名称：</span>
              <span class="item_label">{{ item.name }}</span>
            </div>
            <div class="Item_Text">
              <span class="item_val">空域类型：</span>
              <span class="item_label">{{ item.typeName }}</span>
            </div>
            <div class="Item_Text">
              <span class="item_val">区域形状：</span>
              <span class="item_label">{{
                item.regionType == "POLYGON" ? "多边形" : item.regionType
              }}</span>
            </div>
            <div
              class="Item_Text"
              v-if="item.height != 1 && item.typeName != '禁飞区'"
            >
              <span class="item_val">限高高度：</span>
              <span class="item_label">{{ item.height }}</span>
            </div>
            <div class="Item_Text">
              <span class="item_val">有效时间：</span>
              <span class="item_label">{{ Effective(item) }}</span>
            </div>
            <el-tooltip content="定位" placement="right">
              <div
                class="iconfont icon-dingwei3 cf f18 ml20 cp pa right10 bottom10"
                @click="showEntiti(item)"
              ></div>
            </el-tooltip>
          </div>
          <div></div>
        </div>
        <div class="DemandList_Footer">
          <el-pagination
            class="cpt-table-pagination"
            @current-change="onChangePage"
            :current-page="paging.pageNo || 1"
            layout="total , prev, pager, next, jumper"
            :total="totalCount || 0"
            :page-size="paging.pageSize || 10"
          />
        </div>
      </div>
    </Dialog>

    <Dialog
      class="create"
      v-if="Create"
      @on-close="closeCreate"
      title="创建空域"
      :left="700"
      :top="282"
      :width="538"
    >
      <el-form
        ref="edit_form"
        :rules="rules.edit_form"
        :model="edit_form"
        class="linear-form vertical"
        label-width="110px"
      >
        <el-form-item label="空域名称" prop="name">
          <el-input
            v-model="edit_form.name"
            placeholder="请输入空域名称"
            clearable
            :readonly="visible.readonly"
          />
        </el-form-item>
        <el-form-item label="空域类型" prop="typeId">
          <el-select
            v-model="edit_form.typeId"
            placeholder="请选择空域类型"
            clearable
            :disabled="visible.readonly"
          >
            <el-option
              v-for="item in airTypeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="区域形状" prop="regionType">
          <el-select
            v-model="edit_form.regionType"
            clearable
            placeholder="请选择区域形状"
          >
            <el-option
              v-for="item in regionTypeList"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="色值选择" prop="color">
          <el-color-picker
            @change="colorChange"
            v-model="edit_form.color"
            style="width: 100%"
          ></el-color-picker>
        </el-form-item>

        <el-form-item
          label="飞行高度"
          prop="height"
          v-if="edit_form.typeId != 2"
        >
          <div style="display: flex; align-items: center">
            <el-input-number
              v-model="edit_form.height"
              placeholder="请输入飞行高度"
              clearable
              :min="1"
              :max="999999999999999"
              :disabled="visible.readonly"
            />
            <span style="font-size: 16px; color: #fff">M</span>
          </div>
        </el-form-item>
        <el-form-item label="透明度">
          <div style="display: flex; align-items: center">
            <el-input-number
              @change="transparencyChange"
              v-model="edit_form.transparency"
              placeholder="请输入飞行高度"
              clearable
              :min="0.1"
              :step="0.1"
              :max="1"
              :disabled="visible.readonly"
            />
          </div>
        </el-form-item>
        <el-form-item label="空域层级" prop="Hierarchy">
          <el-select
            v-model="edit_form.Hierarchy"
            placeholder="请选择空域层级"
            clearable
            :disabled="visible.readonly"
          >
            <!-- <el-option label="飞行区" :value="1"></el-option> -->
            <el-option label="一级空域层级" :value="1"></el-option>
            <el-option label="二级空域层级" :value="2"></el-option>
            <el-option label="三级空域层级" :value="3"></el-option>
            <el-option label="四级空域层级" :value="4"></el-option>
            <el-option label="五级空域层级" :value="5"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属机构" prop="pid">
          <el-cascader
            placeholder="请选择所属机构"
            v-model="edit_form.pid"
            clearable
            :options="allOrganizationselectList"
            :show-all-levels="false"
            :props="{
              children: 'child',
              label: 'anotherName',
              value: 'id',
              checkStrictly: true,
              emitPath: false,
              empty: false,
            }"
            class="button"
          >
          </el-cascader>
        </el-form-item>
        <!-- <el-form-item label="有效期限" > -->
        <!-- <el-date-picker
            v-model="edit_form.date"
            type="datetimerange"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            clearable
            :readonly="readonly"
          >
          </el-date-picker> -->
        <div class="date">
          <el-form-item label="开始有效期限" prop="noFlyStartTime">
            <el-date-picker
              size="mini"
              v-model="edit_form.noFlyStartTime"
              type="datetime"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择开始时间"
              :disabled="visible.readonly"
            />
          </el-form-item>
          <el-form-item
            label="结束有效期限"
            prop="noFlyEndTime"
            class="dateEnd"
          >
            <el-date-picker
              size="mini"
              v-model="edit_form.noFlyEndTime"
              type="datetime"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              @focus="datePickerFocus"
              placeholder="选择结束时间"
              :disabled="visible.readonly"
            />
          </el-form-item>
        </div>

        <!-- </el-form-item> -->
        <el-form-item label="备注" prop="remark">
          <el-input
            v-model="edit_form.remark"
            placeholder="请输入备注"
            type="textarea"
            clearable
            :maxlength="255"
            :readonly="visible.readonly"
          />
        </el-form-item>

        <!-- <el-form-item label="空域状态" prop="status">
          <el-radio-group v-model="edit_form.status" v-if="!readonly">
            <el-radio-button :label="1">启用</el-radio-button>
            <el-radio-button :label="0">禁用</el-radio-button>
          </el-radio-group>
          <div v-else>
            <el-input v-model="editProps.status" :readonly="readonly" />
          </div>
        </el-form-item> -->

        <div class="form-submit-item" v-if="!visible.readonly">
          <div @click="handleSave" class="linear-btn">保存</div>
        </div>
      </el-form>
    </Dialog>

    <!-- <Drawky
      v-if="Create"
      :leftOnclick="(value) => leftOnclick(value)"
      :rigthOnclick="(value) => rigthOnclick(value)"
      :move="(value) => move(value)"
      :readonly="visible.readonly"
    /> -->
    <!-- <Createline /> -->
  </div>
</template>

<script>
let handler, viewer, entity;
let noFlyEntities = [];
import Dialog from "../dialog"; // 弹框组件
import API from "@/api";
import { transformCartesian2WGS84 } from "@/utils/cesium";
import dayjs from "dayjs";
import getgetAllOrganization from "@/api/mechanismPullDown.js"; // 获取机构下拉js
import { mapGetters } from "vuex";
export default {
  components: {
    Dialog,
  },
  data() {
    return {
      airTypeList: [],
      regionTypeList: [
        {
          id: 1,
          val: "POLYGON",
          name: "多边形",
        },
      ],
      pickerOptions: {}, // 设置时间禁选
      allairArray: [], // 存储所有禁飞区域
      dataVal: [],
      paging: {
        pageNo: 1,
        pageSize: 10,
      },
      totalCount: 0,
      //创建空域弹框
      Create: false,
      visible: {
        readonly: false,
        edit: false,
      },
      allOrganizationselectList: null,
      edit_form: {
        pid: null,
        name: null,
        typeId: null,
        height: null,
        // date: null,
        remark: null,
        status: 1,
        region: null,
        regionList: null,
        // noFlyStartTime: null,
        transparency: 0.5,
        color: "#FFFF00",
        Hierarchy: null,
        regionType: "多边形",
      },
      rules: {
        edit_form: {
          name: [
            {
              required: true,
              message: "请输入空域名称",
              trigger: "blur",
            },
            { max: 15, message: "空域名称长度不能大于15", trigger: "blur" },
          ],
          typeId: [
            { required: true, message: "请选择空域类型", trigger: "change" },
          ],
          regionType: [
            { required: true, message: "请选区域形状", trigger: "change" },
          ],
          pid: [
            { required: true, message: "请选择所属机构", trigger: "change" },
          ],
          Hierarchy: [
            { required: true, message: "请选择空域层级", trigger: "change" },
          ],
          height: [
            { required: true, message: "请输入高度", trigger: "change" },
            {
              type: "number",
              min: 1,
              message: "飞行高度必须大于1m",
              trigger: "blur",
            },
          ],
          // date: [
          //   { required: true, message: "请选择预计时间", trigger: "blur" },
          // ]
        },
      },
    };
  },
  computed: {
    ...mapGetters(["dbox_data"]),
  },
  methods: {
    ...getgetAllOrganization,
    async colorChange(color) {
      if (viewer.dataSources._dataSources[0].entities.values.length) {
        await this.$nextTick(() => {
          viewer.dataSources._dataSources[0].entities.values[0].polygon.material =
            Cesium.Color.fromCssColorString(color).withAlpha(
              this.edit_form.transparency
            ); //改变绘制的空域颜色
        });
      }
    },
    async transparencyChange(num) {
      console.log(num, "num");
      if (viewer.dataSources._dataSources[0].entities.values.length) {
        await this.$nextTick(() => {
          viewer.dataSources._dataSources[0].entities.values[0].polygon.material =
            Cesium.Color.fromCssColorString(this.edit_form.color).withAlpha(
              num
            ); //改变绘制的空域颜色
        });
      }
    },

    showEntiti(item) {
      let entity = window.viewer.entities.getById(item.id);
      viewer.flyTo(entity, { duration: 3 });
    },
    getNowTime() {
      // 时分秒限制
      let date;
      if (
        this.edit_form.noFlyStartTime &&
        new Date(this.edit_form.noFlyStartTime).getTime() > Date.now()
      ) {
        date = new Date(this.edit_form.noFlyStartTime);
      } else {
        date = new Date();
      }
      let hours = date.getHours();
      let minute = date.getMinutes();
      let second = date.getSeconds();
      if (hours < 10) hours = "0" + hours;
      if (minute < 10) minute = "0" + minute;
      if (second < 10) second = "0" + second;
      let timeStr = `${hours}:${minute}:${second}`;
      return timeStr;
    },
    datePickerFocus() {
      // 获取焦点时位结束时间做选择限制
      this.pickerOptions = {
        disabledDate: (time) => {
          // 日期选择限制
          if (
            this.edit_form.noFlyStartTime &&
            new Date(this.edit_form.noFlyStartTime).getTime() > Date.now()
          ) {
            return (
              time.getTime() <
              new Date(this.edit_form.noFlyStartTime).getTime() - 8.64e7
            );
          } else {
            return time.getTime() < Date.now() - 8.64e7;
          }
        },
        selectableRange: "00:00:00 - 23:59:59", //  时分秒选择限制
      };
    },
    // 绘制左击回调
    leftOnclick(data) {
      // if (data.index === -1) {
      this.edit_form.regionList = data.positions;
      // }
    },
    // 绘制右击回调
    async rigthOnclick(data) {
      if (data.positions && data.positions.type === 1) {
        this.edit_form.regionList = null;
      } else {
        this.edit_form.regionList = data.positions;
        await this.$nextTick(() => {
          console.log(viewer.dataSources, " viewer.dataSources");
          if (viewer.dataSources._dataSources[0].entities.values.length) {
            viewer.dataSources._dataSources[0].entities.values[0].polygon.material =
              Cesium.Color.fromCssColorString(this.edit_form.color).withAlpha(
                this.edit_form.transparency
              ); //改变绘制的空域颜色
          }
        });
      }
    },
    // 绘制移动回调
    move(data) {
      this.edit_form.regionList = data.positions;
    },
    oneArrToTwoArr(data) {
      let newData = [];
      for (let i = 0; i < Math.ceil(data.length / 2); i++) {
        newData[i] = [];
        newData[i].push(data[i * 2]);
        newData[i].push(data[i * 2 + 1]);
      }
      return newData;
    },
    async handleSave() {
      this.$refs["edit_form"].validate(async (valid) => {
        console.log(this.edit_form.regionList, "regionList");
        if (!this.edit_form.regionList) {
          // 判断是否绘制了空域
          this.$message.warning("请绘制空域");
        } else if (valid) {
          const { date, ...rest } = this.edit_form;
          let aa = [],
            cc = [];
          this.edit_form.regionList.map((item) =>
            aa.push(transformCartesian2WGS84(item))
          );
          aa.map((item) => cc.push(item.lng, item.lat));
          let zx = cc.concat();
          let first = cc[0],
            last = cc[cc.length - 1];
          zx.shift();
          zx.pop();
          let fv = [];
          let asx = this.oneArrToTwoArr(zx);
          asx.map((item) => fv.push([item[0], item[1]].join(" ")));
          let gg = fv.toString();
          let yg = first + "," + gg + "," + last;

          const params = {
            ...rest,
            // noFlyStartTime: dayjs(rest.noFlyStartTime).format(
            //   "YYYY-MM-DD HH:mm:ss"
            // ),
            // noFlyEndTime: dayjs(rest.noFlyEndTime).format(
            //   "YYYY-MM-DD HH:mm:ss"
            // ),

            regionList:
              this.editProps &&
              this.editProps.regionList === this.edit_form.regionList
                ? rest.regionList
                : cc,
            region:
              this.editProps && this.editProps.region === yg
                ? rest.region
                : yg &&
                  this.editProps &&
                  this.editProps.region &&
                  !this.edit_form.regionList[0].x
                ? rest.region
                : yg,
            pid: this.edit_form.pid,
            regionType: this.edit_form.regionType,
            airspaceSource: 1,
          };

          let res;
          if (this.editProps) {
            res = await API.AIRSPACE.edit({ ...params, id: this.editProps.id });
          } else {
            res = await API.AIRSPACE.airspaceAdd(params);
          }

          if (res.status === 1) {
            this.$el_message(this.editProps ? "修改成功" : "创建成功");
            this.$refs["edit_form"].resetFields();
            this.Create = false;
            this.closeCreate();
            this.async_getList();
            this.async_get_list();
          } else {
            this.$message.error(res.message);
          }
        }
      });
    },
    closeCreate() {
      handler.removeInputAction(MMCM.DrawEventType.LEFT_CLICK);
      handler.removeInputAction(MMCM.DrawEventType.RIGHT_CLICK);
      handler.removeInputAction(MMCM.DrawEventType.MOUSE_MOVE);
      handler.destroy(); // 吧点击的功能也去掉选点的功能也去掉
      if (!this.readonly) {
        this.showLine();
      }
      this.$refs["edit_form"].resetFields();
      this.visible.edit = false;
      this.visible.readonly = false;
      this.editProps = null;

      this.edit_form.name = null;
      this.edit_form.typeId = null;
      this.edit_form.height = null;
      this.edit_form.noFlyStartTime = null;
      this.edit_form.noFlyEndTime = null;
      this.edit_form.remark = null;
      this.edit_form.status = 1;
      this.edit_form.region = null;
      this.edit_form.regionList = null;
      this.edit_form.transparency = 0.5;
      this.edit_form.shape = null;
      this.Create = false;
    },
    create() {
      this.Create = true;
      this.initV();
    },
    closeIDsPopup() {
      handler = null;
      this.$emit("closeDemand");
    },
    onChangePage(val) {
      this.paging.pageNo = val;
      this.async_getList();
    },
    // Effective(item) {
    //   console.log(item);
    //   this.edit_form.shape = null;
    //   this.Create = false
    // },
    // create() {
    //   this.Create = true
    //   this.initV()
    // },
    // closeIDsPopup() {
    //   this.$emit('closeDemand');
    // },
    Effective(item) {
      // console.log(item);
      if (!item.noFlyStartTime) {
        return "暂无";
      }
      return `${item.noFlyStartTime} - ${item.noFlyEndTime}`;
    },
    async async_getList() {
      let res = await API.AIRSPACE.selectLikeList({
        ...this.paging,
        status: 1,
      });
      let data = res.data;
      this.paging.pageNo = data.pageNo;
      this.paging.pageSize = data.pageSize;
      this.totalCount = data.totalCount;
      this.dataVal = data.data;
    },
    initV() {
      // 新增空域
      handler = new MMCM.DrawPolygonHandler({
        viewer,
      });
      // 禁止双击事件
      viewer.trackedEntity = undefined;
      viewer.cesiumWidget.screenSpaceEventHandler.removeInputAction(
        Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK
      );

      handler.setInputAction((data) => {
        this.leftOnclick(data);
      }, MMCM.DrawEventType.LEFT_CLICK);

      handler.setInputAction((data) => {},
      MMCM.DrawEventType.LEFT_DOUBLE_CLICK);

      handler.setInputAction((data) => {
        this.rigthOnclick(data);
      }, MMCM.DrawEventType.RIGHT_CLICK);

      handler.setInputAction((data) => {
        this.move(data);
      }, MMCM.DrawEventType.MOUSE_MOVE);
    },
    async async_get_list() {
      // 空域可视化
      if (this.allairspce) {
        if (this.search.name === null || this.search.name === "") {
          this.$message.warning("请先输入要搜索的空域");
        } else {
          let res = await API.AIRSPACE.selectLikeList({
            ...this.search,
            status: 1,
            pageNo: 1,
            pageSize: 10000,
          });
          if (res.status === 1 && res.data.data.length == 0) {
            this.$message.warning("未搜索到相关空域");
          } else {
            viewer.entities.removeAll();
            this.viewList = res.data.data;
            this.add();
            viewer.flyTo(viewer.entities, {
              // 让它展示完全
              offset: {
                heading: viewer.camera.heading,
                pitch: Cesium.Math.toRadians(-90),
                roll: 0.0,
              },
            });
          }
        }
      } else {
        // 新增/修改/查看空域
        if (entity) {
          viewer.entities.removeAll();
        }
        let res = await API.AIRSPACE.selectLikeList({
          ...this.search,
          status: 1,
          pageNo: 1,
          pageSize: 10000,
        });
        if (res.status === 1) {
          // 根据airspcelist判断是否是修改空域，所有空域列表中过滤掉当前空域
          if (this.airspcelist && this.airspcelist.id) {
            let list = res.data.data;
            list.length > 0
              ? (this.viewList = list.filter(
                  (item) => item.id != this.airspcelist.id
                ))
              : (this.viewList = list);
          } else {
            this.viewList = res.data.data;
          }
          this.add();
        } else {
          this.$message.error(res.message);
        }
      }
    },
    add() {
      this.viewList.map((item, index) => {
        let positions = item.region.split(" ").join(",").split(",");
        if (positions && item.typeId === 2) {
          this.allairArray.push(positions);
        }
        if (positions.length > 3) {
          let hierarchy = Cesium.Cartesian3.fromDegreesArray(positions);
          let position = Cesium.BoundingSphere.fromPoints(hierarchy).center;
          if (positions.length % 2 !== 0) return;
          entity = viewer.entities.add({
            id: item.id,
            name: "Red polygon on surface",
            position,
            label: {
              text: item.name,
              font: "20px monospace",
              horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
              verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
              pixelOffset: new Cesium.Cartesian2(5, 5),
              disableDepthTestDistance: Number.POSITIVE_INFINITY,
              fillColor: Cesium.Color.WHITE,
              distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
                0,
                200000
              ),
            },
            polygon: {
              hierarchy,
              material: item.color
                ? Cesium.Color.fromCssColorString(item.color).withAlpha(
                    item.transparency
                  )
                : item.typeId === 2
                ? Cesium.Color.RED.withAlpha(0.2)
                : Cesium.Color.YELLOW.withAlpha(0.2),
              outline: true,
              outlineColor: Cesium.Color.BLACK,
            },
          });
          noFlyEntities.push(entity);
        }
      });
      // 将所有禁飞区回传到父组件
      // this.addallairArray(this.allairArray)
    },
    showLine() {
      // 去掉鼠标旁边的提示
      const ces = document.getElementsByClassName("cesium-tooltip")[0];
      const body = document.getElementsByTagName("body")[0];
      if (ces) {
        body.removeChild(ces);
      }
    },
    async getTypeList() {
      let res = await API.AIRSPACE.getTypeList();
      this.airTypeList = res.data;
    },
  },
  async created() {
    await this.async_getList();
    await this.getTypeList();
  },
  mounted() {
    viewer = window.viewer;
    this.async_get_list();
    this.getAllOrganization();
  },
  destroyed() {
    if (!this.readonly) {
      this.showLine();
    }
    if (handler) {
      handler.destroy(); // 吧点击的功能也去掉选点的功能也去掉
      handler = null;
    }
    if (entity) {
      viewer.entities.removeAll();
      handler;
    }
  },
  watch: {
    "edit_form.regionList"(val) {
      // console.log(val);
    },
    // "edit_form.noFlyStartTime"(val) {
    //   // 监视有效期限开始时间，如果大于结束时间，清空结束时间
    //   if (this.edit_form.noFlyEndTime && val) {
    //     if (
    //       new Date(val).getTime() >
    //       new Date(this.edit_form.noFlyEndTime).getTime()
    //     ) {
    //       this.edit_form.noFlyEndTime = null;
    //     }
    //   }
    // },
    // "edit_form.noFlyEndTime"(val) {
    //   // 监视结束时间
    //   if (val) {
    //     const date = new Date(new Date(val).setHours(0, 0, 0, 0)).getTime();
    //     let today = new Date(new Date().setHours(0, 0, 0, 0)).getTime();
    //     if (
    //       this.edit_form.noFlyStartTime &&
    //       new Date(this.edit_form.noFlyStartTime).getTime() > Date.now()
    //     ) {
    //       today = new Date(
    //         new Date(this.edit_form.noFlyStartTime).setHours(0, 0, 0, 0)
    //       ).getTime();
    //     }
    //     if (date > today) {
    //       // 如果选的日期就不是和开始时间或是当天同一天
    //       this.pickerOptions.selectableRange = "00:00:00 - 23:59:59";
    //     } else {
    //       // 如果是同一天
    //       this.pickerOptions.selectableRange = `${this.getNowTime()} - 23:59:59`;
    //     }
    //     if (today == new Date(new Date().setHours(0, 0, 0, 0)).getTime()) {
    //       // 更新一下结束时间 因选择的时候它的时分秒是0
    //       if (
    //         new Date(this.edit_form.noFlyEndTime).getHours() !=
    //         new Date().getHours() &&
    //         new Date(this.edit_form.noFlyEndTime).getDay() ==
    //         new Date().getDay() &&
    //         new Date(this.edit_form.noFlyEndTime).getDate() ==
    //         new Date().getDate() &&
    //         new Date(this.edit_form.noFlyEndTime).getHours() == 0
    //       ) {
    //         if (
    //           this.edit_form.noFlyStartTime &&
    //           new Date(this.edit_form.noFlyStartTime).getTime() > Date.now()
    //         ) {
    //           this.edit_form.noFlyEndTime = new Date(
    //             this.edit_form.noFlyStartTime
    //           );
    //         } else {
    //           this.edit_form.noFlyEndTime = new Date();
    //         }
    //       }
    //     } else {
    //       if (
    //         new Date(this.edit_form.noFlyEndTime).getHours() !=
    //         new Date(this.edit_form.noFlyStartTime).getHours() &&
    //         new Date(this.edit_form.noFlyEndTime).getDay() ==
    //         new Date(this.edit_form.noFlyStartTime).getDay() &&
    //         new Date(this.edit_form.noFlyEndTime).getDate() ==
    //         new Date(this.edit_form.noFlyStartTime).getDate() &&
    //         new Date(this.edit_form.noFlyEndTime).getHours() == 0
    //       ) {
    //         this.edit_form.noFlyEndTime = new Date(
    //           this.edit_form.noFlyStartTime
    //         );
    //       }
    //     }
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
.Airspace {
  width: 100%;
  height: 78vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .Airspace_Body {
    flex: 1;
    overflow-y: auto;

    .Body_Item {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: calc(100% - 6px);
      background: rgba(3, 24, 66, 0);
      border: 1px solid #73a6d1;
      backdrop-filter: blur(4px);
      margin-bottom: 8px;
      padding: 6px 0;

      .Item_Text {
        width: calc(50% - 20px);
        padding: 14px 10px;

        .item_val {
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #aab7c3;
        }

        .item_label {
          color: #fff;
        }
      }
    }
  }

  .DemandList_Footer {
    //列表底部
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;

    .cpt-table-pagination {
      //右下分页组件样式
      height: 50px;
      display: flex;
      align-items: center;

      ::v-deep {
        button {
          background: transparent;
          width: 40px;
          height: 40px;
          box-sizing: border-box;
          // border: 1px solid #488cff;
          font-size: 18px;
          letter-spacing: 0;
          text-align: center;
          font-weight: 400;
          border-radius: 4px;
          line-height: 40px;
          margin-right: 8px;
          box-sizing: border-box;
          color: #b6d4ff;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;

          &:disabled {
            background: transparent;
            color: #b6d4ff;
            opacity: 0.3;
          }
        }

        .el-pagination__total {
          font-size: 18px;
          color: #b6d4ff;
          letter-spacing: 0;
          text-align: center;
          font-weight: 400;

          text-align: center;
          height: 40px;
          line-height: 40px;
          display: inline-block;
          margin: 0px;
        }

        .el-pager {
          .number {
            background: transparent;
            font-size: 12px;
            color: #b6d4ff;
            text-align: center;
            font-weight: lighter;
            width: 25px;
            height: 25px;
            box-sizing: border-box;
            // border: 1px solid #488cff;
            font-size: 18px;
            letter-spacing: 0;
            text-align: center;
            border-radius: 4px;
            margin-right: 8px;
            box-sizing: border-box;

            &.active {
              opacity: 0.8;
              // background-image: linear-gradient(
              //   180deg,
              //   rgba(44, 123, 255, 0.5) 0%,
              //   rgba(72, 140, 255, 0) 100%
              // );
              background-color: #2647ee;
              color: #ffffff;
            }
          }

          .more {
            background: transparent;
            // line-height: 40px;
            width: 40px;
            // height: 40px;

            box-sizing: border-box;
            // border: 1px solid #629fff;
            border-radius: 4px;
            margin-right: 8px;
            font-size: 20px;
            color: #b6d4ff;
          }
        }

        .el-pagination__jump {
          font-size: 18px;
          color: #b6d4ff;
          letter-spacing: 0;
          text-align: center;
          font-weight: 400;
          width: 150px;
          height: 40px;
          margin-left: 6px;
          line-height: 40px;
          display: flex;
          align-items: center;

          .el-input {
            box-sizing: border-box;
            height: 100%;
            width: 84px;

            .el-input__inner {
              width: 60px;
              height: 30px;
              border-radius: 2px;
              border: 1px solid #b6d4ff;
              margin-top: 8px;
              // border: 1px solid #629fff;
              border-radius: 4px;
              background: transparent;
              // height: 100%;
              box-sizing: border-box;
              padding: 0;
              font-size: 18px;
              color: #90b2ec;
              letter-spacing: 0;
              text-align: center;
              font-weight: 400;
            }
          }
        }
      }

      &.mini {
        height: 100%;
        display: flex;
        align-items: center;

        ::v-deep {
          button {
            height: 20px;
            width: 20px;
            line-height: 20px;
            background: transparent;
            border: none;
            color: #4d7592;
            min-width: auto;

            &.btn-next {
              margin-left: 8px;
            }
          }

          .el-pagination__total {
            font-size: 14px;
            color: #4d7592;
          }

          .el-pagination__sizes {
            display: none;
          }

          .el-pager {
            height: 40px;

            .number {
              height: 100%;
              width: auto;
              line-height: 20px;
              background: transparent;
              border: none;
              font-size: 14px;
              color: #4d7592;
              letter-spacing: 0;
              text-align: center;
              font-weight: 500;
              min-width: 20px;
              margin-right: 0;

              &.active {
                opacity: 1;
                color: #0099ff;
              }
            }

            .more {
              height: 100%;
              width: 20px;
              line-height: 20px;
              font-size: 12px;
              border: none;
              min-width: auto;
              margin-right: 0px;

              &::before {
                line-height: 20px;
              }
            }
          }

          .el-pagination__jump {
            height: 100%;
            font-size: 14px;
            color: #4d7592;
            letter-spacing: 0;
            text-align: center;
            font-weight: 400;

            .el-input {
              .el-input__inner {
                border: 1px solid #4d7592;
                border-radius: 2px;
                width: auto;
                min-width: 20px;
                font-size: 14px;
                color: #4d7592;
                letter-spacing: 0;
                text-align: center;
                font-weight: 500;
              }
            }
          }
        }
      }
    }
  }
}

::v-deep {
  .linear-form.vertical .el-form-item {
    height: 40px;

    .el-color-picker__trigger {
      width: 100%;

      .el-color-picker__color {
        .el-color-picker__empty.el-icon-close {
          right: unset;
        }
      }
    }

    .el-form-item__label {
      line-height: 40px;
      float: left;
      text-align: right;
      color: #aab7c3;
    }

    .el-form-item__content {
      .el-input {
        width: 100%;

        .el-input__inner {
          width: 100%;
        }
      }

      .button.el-cascader {
        width: 100%;

        .el-input.el-input--suffix {
          width: 100%;
        }
      }

      .el-select {
        width: 100%;
        height: 30px;
      }

      .el-input-number {
        //添加数据按钮右侧
        width: 100%;
        line-height: 38px;

        .el-input-number__increase {
          border-left: 1px solid #768094;
          height: 30px;
          line-height: 32px;
          margin-top: 2px;
        }

        //添加数据按钮左侧
        .el-input-number__decrease {
          border-right: 1px solid #768094;
          height: 30px;
          line-height: 32px;
          margin-top: 2px;
        }
      }
    }
  }
}
</style>
