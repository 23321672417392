<template>
    <!-- 右侧 -->
    <div class="list">
        <!-- 顶部模式 -->
        <div class="pattern">
            <!-- 赛选栏 -->
            <div class="select hwb-flex">
                <!-- 时间 -->
                <el-date-picker class="button-date" v-model="patternDate" type="daterange" format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                </el-date-picker>
                <div class="right hwb-flex">
                    <div class="button" >统计模式</div>
                    <!-- <div class="button" style="background:#162C9A " @click="changeModel">可视化模式</div> -->
                </div>
            </div>
            <!-- 一列图标 -->
            <div class="pic-list hwb-flex">
                <div class="li hwb-flex-column">
                    <div class="text">{{flyInfo.taskCount}} (pcs)</div>
                    <div class="img1 img"></div>
                    <div class="text2">总任务数量</div>
                </div>
                <div class="li hwb-flex-column">
                    <div class="text">{{flyInfo.flightCount}} (次)</div>
                    <div class="img2 img"></div>
                    <div class="text2">总飞行架次</div>
                </div>
                <div class="li hwb-flex-column">
                    <div class="text">{{ flightDistanceCount(flyInfo.flightDistanceCount)}} (km)</div>
                    <div class="img3 img"></div>
                    <div class="text2">总飞行里程</div>
                </div>
                <div class="li hwb-flex-column">
                    <div class="text">{{getHouser(flyInfo.flightTimeCount)}} (h)</div>
                    <div class="img4 img"></div>
                    <div class="text2">总飞行时长</div>
                </div>
                <div class="li hwb-flex-column">
                    <div class="text">{{flyInfo.taskPhotoCount}} (pcs)</div>
                    <div class="img5 img"></div>
                    <div class="text2">取证图片总数</div>
                </div>
                <div class="li hwb-flex-column">
                    <div class="text">{{flyInfo.waringCount}} (次)</div>
                    <div class="img6 img"></div>
                    <div class="text2">ai预警总次数</div>
                </div>
            </div>
        </div>
        <!-- 列表 -->
        <div class="data-list pr">
            <div>
                <el-input class="button" v-model="taskTitle" placeholder="请输入任务名称" clearable></el-input>
                <div class="search" @click="getdataSource(deviceHardId)">搜索</div>
                <div class="search" @click="reset">重置</div>

            </div>
            <el-table :data="dataSource" class="hwb-table-new"
                :header-row-style="{ 'background-color': 'rgba(138,152,177,0.28)', color: '#fff', 'font-size': '14px' }"
                :header-row-class-name="'header-row-class-name'" :max-height="550" :row-class-name="tableRowClassName"
                row-key="id" :tree-props="{ children: 'child', hasChildren: 'hasChildren' }"
                :cell-style="{ textAlign: 'center' }" :highlight-current-row="false"
                :header-cell-style="{ textAlign: 'center' }">
                <el-table-column prop="id" label="序号" :width="60"></el-table-column>
                <el-table-column prop="deviceName" label="无人机名称"></el-table-column>
                <el-table-column prop="flightSortieName" label="飞行架次号(次)"></el-table-column>
                <el-table-column prop="executeTime" :show-overflow-tooltip="true" label="飞行时间"></el-table-column>
                <el-table-column prop="taskTitle" label="任务名称"></el-table-column>
                <el-table-column prop="flightDistance" label="飞行里程(m)">
                    <template slot-scope="scope">
                        <div class=" cp">{{scope.row.flightDistance}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="flightTime" label="飞行时长(min)">
                    <template slot-scope="scope">
                        <div class=" cp">{{ getMin(scope.row.flightTime)}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="photoCount" label="取证上传图片数"></el-table-column>
                <el-table-column prop="aiCount" label="AI预警数"></el-table-column>
                <el-table-column prop="action" label="操作" width="180">
                    <template slot-scope="scope">
                        <div class="table-button cp" @click="showLocalhost(scope.row)">历史回放</div>
                    </template>
                </el-table-column>
            </el-table>
            <PageCom class="bottom0 pa" :pagination="pagination" @on-pageChange="pageChange" />
        </div>
    </div>
</template>
<script>
import PageCom from "@/components/common/page-com-hwb"; // 分页组件
import API from "@/api";
export default {
    data() {
        return {
            taskTitle: '', // 列表赛选
            patternDate: null, // 模式时间选择
            flyInfo: { // 无人机飞行信息
                taskCount: 0,
                flightDistanceCount: 0,
                flightTimeCount: 0,
                taskPhotoCount: 0,
                flightCount: 0,
                waringCount: 0,
            },
            pagination: {
                page: 1,
                pageSize: 5,
                total: 0,
            },
            dataSource: []
        }
    },
    props: {
        deviceHardId: {
            type: String,
            default: () => ''
        }
    },
    components: {
        PageCom,
    },
    methods: {
        reset(){
            this.pagination={
                page: 1,
                pageSize: 5,
                total: 0,
            }
            this.taskTitle = null
            this.patternDate=null
            this.getdataSource();
        },
        flightDistanceCount(num){
            if(num){
             return   (num / 1000).toFixed(2)
            }
            return 0
        },
        getHouser(num){
            if(num){
             return   (num / 3600).toFixed(2)
            }
            return 0
        },
        getMin(num){
            if(num){
             return   (num / 60).toFixed(2)
            }
            return 0
        },
        // 切换模型
        changeModel(){
            this.$emit('changeModel',false)
        },
        async getdataSource(deviceHardId) { // 获取列表数据
            let { taskTitle, pagination } = this
            let parmas = {
                deviceHardId: deviceHardId || this.deviceHardId,
                startTime: this.patternDate && this.patternDate[0] || null,
                endTime: this.patternDate && this.patternDate[1] || null,
                taskTitle: taskTitle,
                pageNum: pagination.page,
                pageSize: pagination.pageSize,
            }
            const res = await API.TASK.getSorties(parmas)
            if (res.status == 1) {
                this.dataSource = res.data.data.sorties
                for (const key in this.flyInfo) {
                    if (Object.hasOwnProperty.call(this.flyInfo, key)) {
                        this.flyInfo[key] = res.data.data[key];
                    }
                }
                this.pagination.total = res.data.totalCount
            }
        },
        pageChange(page) { // 翻页操作
            this.pagination.page = page;
            this.getdataSource();
        },
        tableRowClassName({ row, rowIndex }) {  // 控制任务状态
            if (rowIndex % 2 == 0) {
                return 'dan-row';
            } else if (rowIndex % 2 == 1) {
                return 'shuang-row';
            }
            return '';
        },
        showLocalhost(row){
            this.$emit('changeModel',row)
        }
    }
}
</script>
<style lang="scss" scoped>
/* 右侧 */
.list {
    // min-width: 1352px;
    width: 100%;
    height: 77.5vh;
    overflow-y: auto;
    margin-left: 30px;
    .pattern {
        width: 100%;

        .select {
            .button-date {
                margin-right: 10px;
                width: 320px;
            }

            .right {
                width: 210px;
                position: relative;

                .button {
                    cursor: pointer;
                    width: 94px;
                    height: 30px;
                    color: #fff;
                    text-align: center;
                    line-height: 30px;
                    background: rgba(38, 71, 238, 0.71);
                    box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
                    border-radius: 2px;
                    border: 1px solid;
                    border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
                }

                .right-time {
                    position: absolute;
                    top: 2px;
                    right: -56px;
                }
            }
        }

        .pic-list {
            width: 100%;
            margin-top:10px;

            .li {
                width: 180px;
                padding: 0 0px;

                .text {
                    font-size: 24px;
                    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
                    font-weight: bold;
                    color: #51D87B;
                    line-height: 30px;
                }

                .img1 {
                    background: url('./img/rwzsl.png') no-repeat center center;
                }

                .img2 {
                    background: url('./img/zfxjc.png') no-repeat center center;
                }

                .img3 {
                    background: url('./img/zfxlc.png') no-repeat center center;
                }

                .img4 {
                    background: url('./img/zfxsc.png') no-repeat center center;
                }

                .img5 {
                    background: url('./img/zzpsl.png') no-repeat center center;
                }

                .img6 {
                    background: url('./img/zyjcs.png') no-repeat center center;
                }

                .img {
                    width: 100%;
                    height: 110px;
                    margin: 10px 0;
                }

                .text2 {
                    font-size: 16px;
                    color: #E0E6F2;
                }
            }
        }
    }

    .data-list {
        margin-top: 30px;
        min-height: 53vh;
        .button {
            width: 240px;
            margin-bottom: 20px;
        }

        .search {
            display: inline-block;
            width: 60px;
            margin-left: 14px;
            border-radius: 4px;
            cursor: pointer;
            height: 30px;
            background-color: #000;
            text-align: center;
            line-height: 30px;
            color: #fff;
        }

        .table-button {
            display: inline-block;
            width: 112px;
            height: 30px;
            line-height: 30px;
            background: rgba(38, 71, 238, 0.71);
            box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
            border-radius: 2px;
            border: 1px solid;
            border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
        }
    }

}
</style>