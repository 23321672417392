<template>
  <!-- 飞行成果多任务需求单子任务列表 -->
  <Dialog  class="cgk" @on-close="closeIDsPopup" :closeTitle="'返回'" :title="demandSheetInfo.taskTitle" :width="1490" :top="120" :left="120">
    <!-- 赛选栏 -->
    <div class="select hwb-flex">
      <el-date-picker
        class="button-date"
        v-model="edit_form.date"
        type="daterange"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期">
      </el-date-picker>
      <el-input class="button" v-model="edit_form.name" clearable placeholder="请输入子任务名称"></el-input>
      <div class="search-button cp" @click="async_get_list">搜索</div>
    </div>
    <!-- 内容 -->
    <div class="content">
      <!-- 一条需求单样式 -->
      <div class="one-box" v-for="(item, index) in dataList" :key="index">
        <div class="img" @click="openAchievementLibrary(item)"></div>
        <div class="text">
          <div class="dec"></div>
          <div class="text-c">
            <span class="title">需求单名称：</span>
            <span class="name">{{item.taskTitle}}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 分页 -->
    <pageCom :pagination="pagination" @on-pageChange="pageChange"/>
  </Dialog>
</template>

<script>
import Dialog from '@/components/common/new-dialog/index-tow.vue'; // 弹框组件
import BigDialog from "@/components/common/new-dialog/index.vue"; // 弹框组件(有遮罩层)
import pageCom from "@/components/common/page-com-hwb"; // 分页组件
import API from "@/api";
export default {
  props: {
    demandSheetInfo: {
      type: Object,
      default: ()=>{}
    }
  },
  components: {
    Dialog,
    BigDialog,
    pageCom
  },
  data() {
    return {
      allOrganizationselectList: null,  // 机构下拉数据
      dataList: null, // 列表数据
      edit_form: { // 搜索字段存储
        date: null,
        name: '',
      },
      pagination: { // 分页信息
        pageNum: 1,
        pageSize: 12,
        total: 0,
      },
    }
  },
  methods: {
    closeIDsPopup() {  // 关闭自身, 回到index
      this.$emit('closePopup')
    },
    openAchievementLibrary(item){ // 隐藏自身，去到该子任务飞行成果
      item.isDuo = true
      this.$emit('toAchievementLibrary',item)
    },
    async async_get_list() { // 搜索
      const {date,name} = this.edit_form
      let startTime, endTime
      if(date){
        startTime = date[0]
        endTime = date[1]
      } else {
        startTime = null
        endTime = null
      }
      const { pageNum, pageSize } = this.pagination;
      let params = `?pageNo=${pageNum}&pageSize=${pageSize}&sumTaskId=${this.demandSheetInfo.id}`
      if(name){
        params = params + `&name=${name}`
      }
      if(startTime){
        params = params + `&startTime=${startTime}`
      }
      if(endTime){
        params = params + `&endTime=${endTime}`
      }
      const res = await API.TASK.taskOrderListNew(params)
       
      this.dataList = res.data.data
      this.pagination.total = res.data.totalCount
    },
    pageChange(page){ // 翻页操作
      console.log(page);
      this.pagination.pageNum = page;
      this.async_get_list();
    },

  },
  mounted() {
    this.async_get_list()
  },
};
</script>

<style lang="scss" scoped>
.cgk{
  .select{
    margin: 0px 0 14px 0px;
    width: 700px;
    ::v-deep{
      .el-input__inner{
        background-color: #000;
        border: 0;
        height: 30px;
        line-height: 30px;
        color: #fff;
      }
      .el-range__icon{
        line-height: 26px;
      }
      .el-range__close-icon{
        line-height: 26px;
      }
      .el-range-separator{
        line-height: 24px;
        color: #fff;
      }
    .button {
      margin-right: 10px;
      width: 210px;
      .el-input__suffix{
        margin-top: -5px;
      }
    }
    }
    .button-date{
      margin-right: 10px;
      width: 380px;
    }
    .search-button{
      box-sizing: border-box;
      display: inline-block;
      width: 86px;
      height: 30px;
      background: rgba(38,71,238,0.71);
      box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0,18,55,0.5);
      border-radius: 2px;
      border: 1px solid;
      border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
      font-size: 14px;
      font-weight: 400;
      color: #FFFFFF;
      text-align: center;
      line-height: 30px;
    }
    .search-button:hover{
      background: rgba(38,71,238,0.51);

    }
  }
  .content{
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    .one-box{
      width: 330px;
      height: 240px;
      margin-left: 30px;
      .img{
        width: 100%;
        height: 180px;
        background: url('../img/zrwxqd.png') no-repeat center center;
      }
      .text{
        position: relative;
        width: 100%;
        margin-top: 10px;
        height: 36px;
        padding: 0 10px 0 30px;
        box-sizing: border-box;
        background: linear-gradient(90deg, rgba(90,153,250,0.52) 0%, rgba(0,32,82,0) 100%);
        border-radius: 8px 0px 0px 0px;
        
        .dec{
          
          position: absolute;
          top: 10px;
          left: 8px;
          width: 17px;
          height: 17px;
          background: url('../img/dec.png');
        }
        .text-c{
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          .title{
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #AAB7C3;
            line-height: 36px;
          }
          .name{
            font-size: 16px;
            font-family: D-DIN-Bold, D-DIN;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 36px;
            height: 36px;
          }
        }
        
      }
    }
  }
}
// ::v-deep{
//   .el-input__suffix{
//     line-height: 40px !important;
//   }
// }
</style>