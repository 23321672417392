<template>
  <div class="wih100 ht100 pr">
    <div class="" :class="!model ? 'maxStyle' : 'smallVideo'">
      <video
        id="videoPlayMap"
        class="video-js videoPlayMapStyle wih100 ht100 vjs-default-skin"
        preload="auto"
      >
        <source :src="videoList[videoIndex].storage" type="video/mp4" />
      </video>
      <!-- type="application/x-mpegURL" -->
    </div>
    <div
      class="cpt-cesium-layer-flight-log"
      :class="model ? 'maxStyle' : 'smallVideo'"
      @mouseenter="CesiumLayerMouseEnter"
      @mouseleave="CesiumLayerMouseLeave"
    >
      <div id="layer-container-flight-log"></div>
    </div>
    <div>
      <flightData :flyTimeData="flyTimeData" class="bottom0 pa"></flightData>
    </div>
    <!-- 视频按钮控制 -->
    <div class="videoConroller cf pl10 pr10">
      <div class="jcsb mt10">
        {{ videoConroller.startTime }}
        <el-progress
          class="mt4"
          :show-text="false"
          style="width: 90%"
          :percentage="percentage"
          :color="customColors"
        />
        {{ videoConroller.endTime }}
      </div>
      <div class="wih100 mt20 jcsb">
        <div class="cp wih100 tc">
          <div>
            <img
              class="dib w20 h20"
              :src="videoConroller.status['previous']"
              @click="changeVideo('previous')"
            />
            <img
              @click="handplay(type)"
              class="dib w20 h20 ml30 mr30"
              :src="videoConroller.status[type]"
              alt="视频"
            />
            <img
              class="dib w20 h20"
              :src="videoConroller.status['next']"
              @click="changeVideo('next')"
            />
            <div class="cf fr">
              倍数:
              <el-select
                @change="videoSpeedChange"
                class="w70"
                v-model="bs"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in 16"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
            <div
              class="icon-xinximoshi iconfont w50 h50 f22 fr"
              @click="model = !model"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="pa bottom0 wih100 timeBtn" style="z-index: 10">
      <div
        @click="videoChangeTime(item)"
        class="timeStyle tc"
        :class="videoConroller.startTime == item.num ? 'active' : 'default'"
        v-for="(item, index) in timeArr"
        :key="index"
      >
        {{ item.num }}
      </div>
    </div>
    <!-- 日志信息 -->
    <div class="w300 h700 flightLogBox cf top0 pa">
      <div class="btnBox">
        <JsonExcel :data="dataSourceList" :fields="json_fields" name="统计.xls">
          <div class="f14 btn mb20">日志导出</div>
        </JsonExcel>
        <div class="f14 btn mb20" @click="videoFn">视频导出</div>
      </div>
      <div class="flightLogInfor f14">
        <div>
          任务名称: <span class="aqua">{{ FilghtMapData.taskTitle }}</span>
        </div>
        <div>
          任务时间: <span class="aqua">{{ FilghtMapData.executeTime }}</span>
        </div>
        <div>
          飞行架次号:<span class="aqua">{{
            FilghtMapData.flightSortieName
          }}</span>
        </div>
        <div>
          飞行速度:<span class="aqua">{{
            isNANfn(flyTimeData.groundSpeed)
          }}</span
          ><span class="ml2">m/s</span>
        </div>
        <div>
          爬升率:<span class="aqua">{{ isNANfn(flyTimeData.climbRate) }}</span
          ><span class="ml2">m/s</span>
        </div>
        <div>
          偏航角度:<span class="aqua">{{ isNANfn(flyTimeData.yaw) }}</span
          ><span class="ml2">度</span>
        </div>
        <div>
          俯仰角度:<span class="aqua">{{ isNANfn(flyTimeData.pitch) }}</span
          ><span class="ml2">度</span>
        </div>
        <div>
          翻滚角度:<span class="aqua">{{ isNANfn(flyTimeData.roll) }}</span
          ><span class="ml2">度</span>
        </div>
        <div>
          飞行时长:<span class="aqua">{{
            flyTimeDataFn(flyTimeData.flightTime)
          }}</span
          ><span class="ml2">/min</span>
        </div>
        <div>
          飞行高度:<span class="aqua">{{ isNANfn(flyTimeData.height) }}</span
          ><span class="ml2">/m</span>
        </div>
        <div>
          当前电压:<span class="aqua">{{ isNANfn(flyTimeData.voltage) }}</span
          ><span class="ml4">v</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
let aa;
let plate_data_source = null;
import { Utils } from "@/lib/cesium";
// import GeoJson from '@/data/geo/330481.json'
import GeoJson from "@/data/geo/jy.json";
import { mapGetters } from "vuex";
import flightData from "./flightData/index.vue";
import API from "@/api";
import videojs from "video.js";
import "videojs-contrib-hls";
import "@videojs/http-streaming";
import methods from "./methods/index";
import dayjs from "dayjs";
import JsonExcel from 'vue-json-excel'
import { initMap } from "@/utils/cesium.js";

let models = []
let viewer
export default {
    props: {
        cameraPosition: {
            type: String,
            default: ''
        },
        FilghtMapData: {
            type: Object,
            default: () => { }
        }
    },
    computed: {
        ...mapGetters(["my_layers_data"]),
    },
    components: { flightData, JsonExcel },
    data() {
        return {
            model: true,
            dataSourceList: [],
            json_fields: {
                "序号": "id",    //常规字段
                "无人机名称": "deviceName",
                "飞行架次号(次)": "flightSortieName",
                "飞行时间": "flightStartTime",
                "任务名称": "taskTitle",
                "飞行里程(m)": "flightDistance",
                "飞行时长(min)": "flightTime",
                "取证上传图片数": "photoCount",
                "AI预警数": "aiCount",
            },
            timeArr: [
                {
                    "time": 1.6666666666666667,
                    "num": "00:01"
                },
                {
                    "time": 3.3333333333333335,
                    "num": "00:03"
                },
                {
                    "time": 5,
                    "num": "00:05"
                },
                {
                    "time": 6.666666666666667,
                    "num": "00:06"
                },
                {
                    "time": 8.333333333333334,
                    "num": "00:08"
                },
                {
                    "time": 10,
                    "num": "00:10"
                },
                {
                    "time": 11.666666666666666,
                    "num": "00:11"
                },
                {
                    "time": 13.333333333333332,
                    "num": "00:13"
                },
                {
                    "time": 14.999999999999998,
                    "num": "00:14"
                },
                {
                    "time": 16.666666666666664,
                    "num": "00:16"
                },
                {
                    "time": 18.333333333333332,
                    "num": "00:18"
                },
                {
                    "time": 20,
                    "num": "00:20"
                }
            ],
            options: {
                autoplay: true, //自动播放
                controls: true, //用户可以与之交互的控件
                loop: false, //视频一结束就重新开始
                muted: true, //默认情况下将使所有音频静音
                playsinline: true,
                webkitPlaysinline: true,
                // aspectRatio:"16:9",//显示比率
                playbackRates: [0.5, 1, 1.5, 2],
                language: "zh-CN",
                fullscreen: {
                    options: { navigationUI: "hide" },
                },
            },
            bs: 1,
            VUE_APP_IP: null,
            entity: null,
            percentage: 0,
            customColors: [
                { color: '#79CFFF', percentage: 20 },
                { color: '#e6a23c', percentage: 40 },
                { color: '#5cb87a', percentage: 60 },
                { color: '#1989fa', percentage: 80 },
                { color: '#6f7ad3', percentage: 100 }
            ],
            videoConroller: {
                startTime: "00:00",
                endTime: "00:00",
                status: {
                    play: require('./img/play.png'),
                    suspend: require('./img/suspend.png'),
                    next: require('./img/next.png'),
                    previous: require('./img/previous.png')
                }
            },
            startTime: "",
            type: "play",
            // 模型
            modelData: {
                model: null,
                orientation: null,
                position: null,
                positions: [],
                entity_id: "",//modelid
                polyline_id: "",//航线id
            },
            // 飞机关联时间的数据
            modelDataTime: {
                //   关联时间的无人机位置信息
                positionTime: [],
                //关联时间的无人机姿态信息
                orientationTime: [],
                // 关联时间的飞行数据
                flyTimeData: []

            },
            flyTimeData: {},
            flyData: [],
            // 视频列表
            videoList: [{
                url: ""
            }],
            // 視頻索引
            videoIndex: 0,
            markers: [],
            // 無人機視頻
            player: null,

        };
    },
    methods: {
        ...methods,
        videoFn() {
            //     const a = document.createElement("a");
            // a.href = URL.createObjectURL(this.videoList[this.videoIndex].storage);
            // a.download = `${this.FilghtMapData.taskTitle}.mp4`;
            // a.click();
            // a.remove();

      fetch(this.videoList[this.videoIndex].storage)
        .then((res) => res.blob())
        .then((blob) => {
          const a = document.createElement("a");
          const objectUrl = window.URL.createObjectURL(blob);
          a.download = `飞行日志.mp4`;
          a.href = objectUrl;
          a.click();
          window.URL.revokeObjectURL(objectUrl);
          a.remove();
        });
    },
    getMin(num) {
      if (num) {
        return (num / 60).toFixed(2);
      }
      return 0;
    },
    async excelFn() {
      this.dataSourceList = [
        {
          ...this.FilghtMapData,
          flightTime: this.getMin(this.FilghtMapData.flightTime),
        },
      ];
      // let parmas = {
      //     deviceHardId: this.FilghtMapData.deviceHardId,
      //     pageNum: 1,
      //     pageSize: 10000,
      // }
      // const res = await API.TASK.getSorties(parmas)
      // let _this = this
      // if (res.status == 1) {

      //     this.dataSourceList = res.data.data.sorties.map(item => {
      //         return (
      //             {
      //                 ...item,
      //                 flightTime: _this.getMin(item.flightTime)
      //             }
      //         )
      //     })

      // }
    },
    flyTimeDataFn(time) {
      if (time) {
        return Number(time / 60).toFixed(2) || 0;
      } else {
        return 0;
      }
    },
    dateAdd(d, num) {
      var d = new Date(
        d.substring(0, 4),
        d.substring(5, 7) - 1,
        d.substring(8, 10),
        d.substring(11, 13),
        d.substring(14, 16),
        d.substring(17, 19)
      );
      d.setTime(d.getTime() + num * 1000);
      return (
        d.getFullYear() +
        "-" +
        this.checkTime(d.getMonth() + 1) +
        "-" +
        this.checkTime(d.getDate()) +
        " " +
        this.checkTime(d.getHours()) +
        ":" +
        this.checkTime(d.getMinutes()) +
        ":" +
        this.checkTime(d.getSeconds())
      );
    },
    isNANfn(data) {
      if (data && data === data && data !== "NaN") {
        return Number(data).toFixed(2) || 0;
      } else {
        return 0;
      }
    },
    checkTime(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },
    async getHistoryStreamFn() {
      // "2022-10-21 15:52:04"
      let { FilghtMapData } = this;

      let res = await API.TASK.taskDataList({
        typeId: 2,
        taskId: FilghtMapData.id,
      });
      if (res.code == 200) {
        this.videoList = res.data.records;
        console.log(this.videoList, "this.videoList");
        this.init();
      }
      let time = null;
      try {
        let startTime = this.videoList[this.videoIndex].videoStartEnd;
        time = JSON.parse(startTime)[0].startTime;
        this.startTime = time;
      } catch (error) {}
    },
    async filghtInit() {
      let { FilghtMapData } = this;
      let res = await API.DEVICE.devicecenterSystemServerData({
        deviceHardId: FilghtMapData.deviceHardId,
        flightSortie: FilghtMapData.flightSortieName,
      });
      if (res.status == 1) {
        try {
          this.flyData = res.data;
          this.modelData.position = res.data.position;
          if (res.data[0].longitude != "nan") {
            let position = Cesium.Cartesian3.fromDegrees(
              res.data[0].longitude * 1,
              res.data[0].latitude * 1,
              res.data[0].altitude * 1
            );
            this.modelData.position = position;
            for (let i = 0; i < res.data.length; i++) {
              let data = this.trans_mqtt_location_data(res.data[i]);
              let time = parseInt(res.data[i].dateTime / 1000);
              this.modelDataTime.positionTime[time] = data.position;
              this.modelDataTime.orientationTime[time] = data.orientation;
              this.modelDataTime.flyTimeData[time] = res.data[i];
              this.modelData.positions.push(data.position);
            }
            this.init_Model(FilghtMapData.deviceHardId);
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    init_Model(deviceHardId) {
      const entities = viewer.entities;
      let model = entities.getById(`flight_model_${deviceHardId}`);
      let { modelData, flyData } = this;
      if (!model) {
        modelData.entity_id = `flight_model_${deviceHardId}`;
        let model = this.add_model(entities, modelData, flyData[0]);
        viewer.camera.flyTo({
          destination: model.position.getValue(Cesium.JulianDate.now()),
          maximumHeight: 100,
          complete: () => {
            // 锁定
            viewer.trackedEntity = model;
          },
        });
        model.viewFrom = new Cesium.Cartesian3(0, 0, 1000);
        models.push(model);
      }
      // let polyline = entities.getById(`accident_polyline_${deviceHardId}`)
      // if (!polyline) {
      //     let line = this.add_polyline(entities, modelData)
      //     modelData.polyline_id = line
      // }
    },
    add_model(entities, data, item) {
      return entities.add({
        id: data.entity_id,
        parent: data.parent || null,
        orientation: new Cesium.CallbackProperty(() => data.orientation, false),
        position: new Cesium.CallbackProperty(() => data.position, false),
        position: data.position,
        model: {
          uri: "./Model/uav_v2.glb",
          minimumPixelSize: 50,
          maximumScale: 20000,
          scale: 1,
          // colorBlendMode: Cesium.ColorBlendMode['MIX']
        },
        longitude: {
          type: 28,
          lon: item.longitude == "nan" ? "" : null,
          lat: item.latitude == "nan" ? "" : null,
          height: 0,
          // data: item
        },
        polyline: {
          positions: new Cesium.CallbackProperty(() => data.positions, false),
          material: Cesium.Color.GREEN.withAlpha(0.9),
          width: 5,
        },
      });
    },
    add_polyline(entities, data) {
      return viewer.entities.add({
        id: data.polyline_id,
        parent: data.parent || null,
        polyline: {
          positions: new Cesium.CallbackProperty(() => {
            return data.positions;
          }, false),
          width: 5,
          show: true,
          material: Cesium.Color.fromCssColorString("#6DD400"),
          ...(data.options ? data.options : {}),
        },
      });
    },
    trans_mqtt_location_data(uav_data) {
      if (!uav_data) {
        return uav_data;
      }
      // 获取航线
      let position = Cesium.Cartesian3.fromDegrees(
        uav_data.longitude * 1,
        uav_data.latitude * 1,
        uav_data.altitude * 1
      );
      let positions = [];
      let yaw = Cesium.Math.toRadians(uav_data.yaw + 90),
        pitch = Cesium.Math.toRadians(uav_data.pitch),
        roll = Cesium.Math.toRadians(uav_data.roll),
        hpr = new Cesium.HeadingPitchRoll(yaw, pitch, roll),
        orientation = Cesium.Transforms.headingPitchRollQuaternion(
          position,
          hpr
        );
      if (this.modelData.model) {
        let model = this.modelData.model;
        const curPos = model.position.getValue(Cesium.JulianDate.now());
        if (curPos) {
          const curCart = new Cesium.Cartesian3(curPos.x, curPos.y, curPos.z);
          const afterCart = new Cesium.Cartesian3(
            position.x,
            position.y,
            position.z
          );
          if (curCart.equals(afterCart)) {
          } else {
            // 航线数据
            positions.push(position);
          }
        } else {
        }
      }

            return {
                position,
                positions,
                orientation,
            };
        },
        init_viewer() {
            viewer = initMap(this.my_layers_data, "layer-container-flight-log")
            this.init_events()
        },
        getcameraPosInfo() {
            // 获取 相机姿态信息
            var head = viewer.scene.camera.heading
            var pitch = viewer.scene.camera.pitch
            var roll = viewer.scene.camera.roll
            var info = { 'heading': head, 'pitch': pitch, 'roll': roll };
            // 获取位置 wgs84的地心坐标系，x,y坐标值以弧度来表示
            var position = viewer.scene.camera.positionCartographic //with longitude and latitude expressed in radians and height in meters.
            //以下方式也可以获取相机位置只是返回的坐标系不一样
            // var position = viewer.scene.camera.position //cartesian3 空间直角坐标系
            // var ellipsoid = scene.globe.ellipsoid;
            // var position =ellipsoid.cartesianToCartographic(viewer.scene.camera.position)//
            // 弧度转经纬度
            var longitude = Cesium.Math.toDegrees(position.longitude).toFixed(6)
            var latitude = Cesium.Math.toDegrees(position.latitude).toFixed(6)
            var height = position.height
            return { lng: longitude, lat: latitude, h: height, mat: info }
        },
        // 开启鼠标点击与移动事件
        init_events() {
            let handler = new Cesium.ScreenSpaceEventHandler(viewer.canvas)
            handler.setInputAction((movement) => {
                this.on_plate_left_click(movement)
            }, Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK)
            handler.setInputAction((movement) => {
                //获取当前相机高度
                let height = Math.ceil(viewer.camera.positionCartographic.height)
                this.on_plate_wheel(height)
            }, Cesium.ScreenSpaceEventType.WHEEL)
        },
        on_plate_wheel(height) {
            if (height >= 1.4e7 && !plate_data_source.show) {
                // this.fly_to_plate()
            } else if (height > 80000) {
                // 首页板块封面代码
                // plate_data_source.entities.values.map(item => item.polygon.material = this.imgUrl)
                // plate_data_source.show = true
            }
        },
        CesiumLayerMouseEnter() {
            // 鼠标移入地球添加鼠标滚动效果
            document.addEventListener(`mousewheel`, this.mouseScoll);
        },
        CesiumLayerMouseLeave() {
            // 鼠标移出地球添加鼠标滚动效果
            document.removeEventListener(`mousewheel`, this.mouseScoll);
        },
        get_plate_by_click(moment) {
            let pickedObject = viewer.scene.pick(moment.position)
            if (pickedObject?.id?._name) {
                if (
                    pickedObject &&
                    pickedObject.id &&
                    pickedObject.id._name.indexOf('plate_') != -1
                ) {
                    return pickedObject.id
                } else {
                    return null
                }
            }
        },
        fly_to_globe(options) {
            viewer.trackedEntity && (viewer.trackedEntity = undefined)
            viewer.scene.globe.show = true
            viewer.scene.skyAtmosphere.show = true
            viewer.scene.globe.showGroundAtmosphere = true
            // plate_data_source && (plate_data_source.show = false)
            this.location(options)
        },
        location(options = { destination: origin }, fly_type = 'flyTo') {
            viewer.camera[fly_type](options)
        },
        mouseScoll(e) {
            //鼠标滚轮滚动效果
            /*这里获取元素节点*/
            let oPanel = document.getElementById("app");
            let Ripples = document.getElementsByClassName(
                "hwb-mouse-scrolling-effect"
            );
            if (Ripples.length > 0) {
                return;
            }
            let oSpan = document.createElement(`div`);
            /*浏览器兼容*/
            e = e || window.Event;
            /*获取相关参数*/
            oSpan.style.left = e.clientX + `px`;
            oSpan.style.top = e.clientY + `px`;
            /*给节点赋值参数数值*/
            oSpan.className = "hwb-mouse-scrolling-effect";
            /*在body中添加span标签*/
            oPanel.appendChild(oSpan);
            /*设置定时器 间隔时间为2000毫秒*/
            setTimeout(() => {
                /*清除ospan节点*/
                oSpan.remove();
            }, 600);
        },
        // 加载geo板块
        async init_plate() {
            let promise = Cesium.GeoJsonDataSource.load(GeoJson);
            let dataSource = await promise;
            let entities = dataSource.entities.values;
            for (let i = 0; i < entities.length; i++) {
                let entity = entities[i];
                entity._name = "plate" + "_" + entity._name;
                entity.polygon.outline = true;
                entity.polygon.outlineColor =
                    Cesium.Color.fromCssColorString("#7ecaed");
                entity.polygon.material =
                    Cesium.Color.fromCssColorString("#7ecaed").withAlpha(0.1);
                // this.getColorRamp([0.5, 0.3], false)
                // 首页板块封面代码
                //     // entity.polygon.material= this.imgUrl

        entity.polygon.extrudedHeight = Math.floor(1);

        let positions = entity.polygon.hierarchy._value.positions;
        // 边界线
        entity.polyline = {
          positions: positions.map((item) => {
            let c = Cesium.Cartesian3.clone(item);
            let wgs84 = Utils.transformCartesian2WGS84(c);
            wgs84.alt += 1;
            return Utils.transformWGS842Cartesian(wgs84);
          }),
          width: 4,
          material: Cesium.Color.fromCssColorString("#76d6f0"),
        };

        let center = entity.properties.centroid._value;
        center = Utils.transformWGS842Cartesian({
          lng: center[0],
          lat: center[1],
          alt: 1,
        });

        entity.position = center;
        // 板块乡镇字体
        entity.label = {
          text: entity._name.split("_")[1],
          show: true,
          showBackground: false,
          font: "16px monospace bolder",
          horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          pixelOffset: new Cesium.Cartesian2(0, 0),
          disableDepthTestDistance: Number.POSITIVE_INFINITY,
          fillColor: Cesium.Color.fromCssColorString("#FFF").withAlpha(0.5),
        };
        entity.billboard = {
          show: false,
          image: require("@/assets/images/poi.png"),
          pixelOffset: new Cesium.Cartesian2(0, -30),
          width: 24,
          height: 24,
        };
        await viewer.dataSources.add(dataSource);
      }
      plate_data_source = dataSource;
    },
    // 左击点击事件
    on_plate_left_click(movement) {
      // 首页板块封面代码
      // plate_data_source.entities.values.map(item => item.polygon.material = Cesium.Color.fromCssColorString('#00016c').withAlpha(0.2))
      let plate = this.get_plate_by_click(movement);
      if (plate) {
        // this.g_show_zysb(0, 0, 1)
        let center = plate.properties.center._value;
        this.fly_to_globe({
          destination: Utils.transformWGS842Cartesian({
            lng: center[0],
            lat: center[1],
            alt: 5000,
          }),
        });

        // viewer.camera.flyTo({
        //   destination: Cesium.Cartesian3.fromDegrees(120.625748, 30.369629, 34879.96979198625),
        //   orientation: {
        //     "heading": 0.031173336961421327,
        //     "pitch": -1.1699130213649074,
        //     "roll": 6.283184330860693
        //   }
        // })
      }
    },
    CesiumLayerMouseLeave() {
      // 鼠标移出地球添加鼠标滚动效果
      document.removeEventListener(`mousewheel`, this.mouseScoll);
    },
  },
  mounted() {
    const { VUE_APP_IP } = process.env;
    this.VUE_APP_IP = VUE_APP_IP;
    this.init_viewer();
    this.init_plate();
    this.filghtInit();
    this.getHistoryStreamFn();
    this.excelFn();
  },
  beforeDestroy() {
    const entities = viewer.entities;
    let model = entities.getById(
      `flight_model_${this.FilghtMapData.deviceHardId}`
    );
    if (model) {
      viewer.entities.remove(model);
    }
    this.CesiumLayerMouseLeave();
    if (this.player != null) {
      this.player.dispose(); // dispose()会直接删除Dom元素
    }
    viewer && viewer.destroy();
    viewer = null;
    
  },
};
</script>

<style lang="scss" scoped>
.aqua {
  color: aqua;
}

.smallVideo {
  width: 200px !important;
  height: 112px !important;
  position: absolute;
  // background: yellow;
  left: 14px !important;
  top: 24px !important;
  z-index: 10;
}

.maxStyle {
  width: 1352px !important;
  height: 100% !important;
}

.areaInforBox {
  top: 0;
  z-index: 14;
  width: 372px;
  height: 100%;
  background: rgba(21, 95, 192, 0.3);
  border: 1px solid #73a6d1;
  backdrop-filter: blur(2px);
}

.videoConroller {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 10;
  width: calc(100% - 20px);
  height: 142px;
  background: rgba(21, 95, 192, 0.3);
  border: 1px solid #73a6d1;
  backdrop-filter: blur(4px);
}

.timeBtn {
  display: flex;
  justify-content: space-around;

  .timeStyle {
    width: 66px;
    height: 56px;
    line-height: 56px;
    cursor: pointer;
  }

  .default {
    color: #fff;
    background: linear-gradient(180deg, #5298df 0%, #25518f 60%, #0f3275 100%);
    box-shadow: 0px 2px 4px 0px rgba(3, 5, 49, 0.5),
      inset 0px 0px 23px 0px #69e6ff, inset 0px -2px 2px 0px #120d59,
      inset 0px 3px 4px 0px #a7d8ff;
    border: 2px solid;
    border-image: linear-gradient(
        180deg,
        rgba(219, 242, 255, 1),
        rgba(132, 210, 255, 0)
      )
      2 2;
  }

  .active {
    color: #fdff00;
    background: linear-gradient(
      180deg,
      #ffca00 0%,
      rgba(255, 175, 0, 0.2) 51%,
      rgba(255, 226, 0, 0.5) 100%
    );
    box-shadow: 0px 2px 4px 0px rgba(49, 20, 3, 0.5),
      inset 0px 0px 23px 0px #ffe400, inset 0px -2px 2px 0px #59330d,
      inset 0px 3px 4px 0px #ffd8a7;
    border: 2px solid;
    border-image: linear-gradient(
        180deg,
        rgba(255, 239, 188, 1),
        rgba(255, 229, 132, 0)
      )
      2 2;
  }
}

.cpt-cesium-layer-flight-log {
  // position: absolute;

  // left: 0;
  // bottom: 0;
  // right: 0;
  // top: 0;

  #layer-container-flight-log {
    // background: url("~@/assets/home/bj.png") no-repeat;
    background-size: cover;
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }

  .video {
  }
}

.flightLogBox {
  background: rgba(21, 95, 192, 0.2);
  border: 1px solid #73a6d1;
  backdrop-filter: blur(2px);
  z-index: 80;
  padding: 20px;
  box-sizing: border-box;
  right: -309px;

  .btnBox {
    display: flex;

    .btn {
      margin-right: 20px;
      width: 86px;
      height: 30px;
      line-height: 30px;
      background: rgba(38, 71, 238, 0.71);
      box-shadow: inset 0px 1px 3px 0px #ffffff,
        inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
      border-radius: 2px;
      border: 1px solid;
      text-align: center;
      cursor: pointer;
      border-image: linear-gradient(
          180deg,
          rgba(255, 255, 255, 1),
          rgba(255, 255, 255, 0)
        )
        1 1;
    }
  }

  .flightLogInfor {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    height: 95%;

    div {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
.videoPlayMapStyle {
  ::v-deep {
    .vjs-modal-dialog-content {
      display: none !important;
    }
    .vjs-error-display:before {
      display: none !important;
    }
  }
}
</style>
