<template >
    <div class="wih100 h340 pr">
        <div class="pa right19 cp top10  cf " style="z-index:20" @click="flag = !flag">
            切换
        </div>
        <div v-show="flag" id="containerHistogram" class="wih100 h340">
        </div>
        <div v-show="!flag" id="content-box_person" class="wih100 h340">

        </div>
    </div>
</template>
<script>
import * as echarts from "echarts";
import "echarts-gl";
import API from "@/api";
import highcharts from "highcharts";

export default {
    data() {
        return {
            exempleData: [],
            flag: true,
            dataList: [],
        }
    },

    created() {
      
    },
    async mounted() {
        await this.init()
        this.initOption()

    },
    methods: {
        initOption() {
            let dataList = []
            for (let i = 0; i < this.exempleData.length; i++) {
                const element = this.exempleData[i];
                dataList.push({
                    name: element.name,
                    percent: element.value,
                    'h': 0,
                    bfb: 0,
                    y: element.value
                })
                
            }
            this.dataList = dataList
            // this.dataList = [
            //     {
            //         "name": "DJI御2S",
            //         "y": 2,
            //         "percent": 0.03125,
            //         "h": 0,
            //         "bfb": 0
            //     },
            //     {
            //         "name": "DJI御3",
            //         "y": 4,
            //         "percent": 0.09375,
            //         "h": 0,
            //         "bfb": 0
            //     }
            // ]
            let quantity = 0; // 总数
            this.dataList.forEach((item) => {
                quantity += item.y;
            });
            this.dataList.forEach((item) => {
                item.bfb = parseInt((item.y / quantity) * 100);
                item.h = item.bfb * 0.5 >= 40 ? 40 : item.bfb * 0.5
                // item.h = parseInt(0.86 * item.bfb); // 最高高度60，根据比例渲染高度
                // console.log(this.dataList, "dataList----->>>");
            });
            // 修改3d饼图绘制过程
            var each = highcharts.each,
                round = Math.round,
                cos = Math.cos,
                sin = Math.sin,
                deg2rad = Math.deg2rad;
            highcharts.wrap(
                highcharts.seriesTypes.pie.prototype,
                "translate",
                function (proceed) {
                    proceed.apply(this, [].slice.call(arguments, 1));
                    // Do not do this if the chart is not 3D
                    if (!this.chart.is3d()) {
                        return;
                    }
                    var series = this,
                        chart = series.chart,
                        options = chart.options,
                        seriesOptions = series.options,
                        depth = seriesOptions.depth || 0,
                        options3d = options.chart.options3d,
                        alpha = options3d.alpha,
                        beta = options3d.beta,
                        z = seriesOptions.stacking
                            ? (seriesOptions.stack || 0) * depth
                            : series._i * depth;
                    z += depth / 2;
                    if (seriesOptions.grouping !== false) {
                        z = 0;
                    }
                    each(series.data, function (point) {
                        var shapeArgs = point.shapeArgs,
                            angle;
                        point.shapeType = "arc3d";
                        var ran = point.options.h;
                        shapeArgs.z = z;
                        shapeArgs.depth = depth * 0.75 + ran;
                        shapeArgs.alpha = alpha;
                        shapeArgs.beta = beta;
                        shapeArgs.center = series.center;
                        shapeArgs.ran = ran;
                        angle = (shapeArgs.end + shapeArgs.start) / 2;
                        point.slicedTranslation = {
                            translateX: round(
                                cos(angle) * seriesOptions.slicedOffset * cos(alpha * deg2rad)
                            ),
                            translateY: round(
                                sin(angle) * seriesOptions.slicedOffset * cos(alpha * deg2rad)
                            ),
                        };
                    });
                }
            );
            (function (H) {
                H.wrap(
                    highcharts.SVGRenderer.prototype,
                    "arc3dPath",
                    function (proceed) {
                        // Run original proceed method
                        var ret = proceed.apply(this, [].slice.call(arguments, 1));
                        ret.zTop = (ret.zOut + 0.5) / 100;
                        return ret;
                    }
                );
            })(highcharts);
            highcharts.chart('content-box_person', {
                chart: {
                    animation: true,
                    backgroundColor: "none",
                    type: "pie", //饼图
                    margin: [0, 0, 0, 0],
                    options3d: {
                        enabled: true, //使用3d功能
                        alpha: 30, //延y轴向内的倾斜角度
                        beta: 0,
                    },
                    title: {
                        floating: true,
                        text: '圆心显示的标题'
                    },
                    events: {
                        load: function () {
                            var each = highcharts.each,
                                points = this.series[0].points;
                            each(points, function (p, i) {
                                p.graphic.attr({
                                    translateY: -p.shapeArgs.ran,
                                });
                                p.graphic.side1.attr({
                                    translateY: -p.shapeArgs.ran,
                                });
                                p.graphic.side2.attr({
                                    translateY: -p.shapeArgs.ran,
                                });
                            });
                        },
                    },
                },
                legend: {
                    enabled: false, // 关闭图例
                    align: "right", //水平方向位置
                    verticalAlign: "top", //垂直方向位置
                    layout: "vertical",
                    x: 20,
                    y: 30,
                    symbolWidth: 1,
                    symbolHeight: 1,
                    symbolRadius: "50%", // 修改成圆
                    itemMarginBottom: 8,
                    useHTML: true,
                    itemStyle: {
                        color: "#f4f4f6",
                        fontSize: 12,
                    },
                },
                title: {
                    // enabled: false,
                    text: "",
                },
                subtitle: {
                    floating: false,
                    text: "",
                    align: 'center',
                    verticalAlign: "middle",
                    style: {
                        color: '#c0d8fa'
                    }
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: false, // 禁用点击
                        cursor: "pointer",
                        depth: 15,
                        showInLegend: false,
                        size: "78%", // 外圈直径大小
                        innerSize: 0, // 内圈直径大小
                        center: ["50%", "55%"],
                        colors: [
                            "#0ecbff",
                            "#d26b00",
                            "#41e0ba",
                            "#e9483b",
                            "rgb(13,145,239)",
                            "rgb(167,67,234)",
                        ],
                        dataLabels: {
                            useHTML: true,
                            enabled: true, //是否显示饼图的线形tip
                            distance: 5,
                            borderColor: '#007acc',
                            align: "center",
                            // verticalAlign: 'top',
                            position: "right",
                            format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                            color: "#ffffff",
                            style: {
                                textOutline: "none",
                                fontSize: 14,
                            },
                        },
                    },
                },
                credits: {
                    enabled: false, // 禁用版权信息
                },
                series: [
                    {
                        type: "pie",
                        name: "数量",
                        data: this.dataList,
                    },
                ],
            });
        },
        async init() {
            // flightManagement
            let res = await API.FLIGHTMANAGEMENT.organUserList()
            if (res.code == 200) {
                this.exempleData = res.data.map(item => {
                    return {
                        name: item.anotherName,
                        // value:item.offline +item.online
                        value: item.grade
                    }
                })
                this.verticalBarChartsConfig(this.exempleData)
            }
        },
        //筛选出数据中对应键值组成的数组
        getArrByKey(data, k) {
            let key = k || "value";
            let res = [];
            if (data) {
                data.forEach(function (t) {
                    res.push(t[key]);
                });
            }
            return res;
        },

        //给每一项数据加上symbolPosition，即图形相对于原本位置的偏移
        getSymbolData(data, symbol) {
            let arr = [];
            for (var i = 0; i < data.length; i++) {
                arr.push({
                    ...data[i],
                    symbolPosition: symbol ? symbol : 'start',
                })
            }
            return arr;

        },
        verticalBarChartsConfig(data) {
            let exempleData = [
                { name: '2015', value: 11158 }, { name: '2016', value: 3012 },
                { name: '2017', value: 9449 }, { name: '2018', value: 47 },
                { name: '2019', value: 53 }, { name: '2020', value: 2394 },
                { name: '2021', value: 2823 },
            ];
            data = data || exempleData;
            //最大值
            let maxValue = Math.max.apply(null, this.getArrByKey(data));
            //用于背景半透明柱子渲染
            let bgArr = data.map((item, index) => { return maxValue + maxValue * 0.2; });
            let option = {
                tooltip: {
                    trigger: 'item',
                    backgroundColor: 'rgba(5,28,44,0.9)',
                    borderColor: 'rgba(8,145,166,0.7)',
                    borderWidth: 1,
                    padding: [10, 10],
                    axisPointer: {
                        type: 'line',
                        lineStyle: {
                            color: 'rgba(255,255,255,0.3)',
                            width: 24,
                            cap: 'round'
                        }
                    },
                    textStyle: {
                        fontSize: 14
                    },
                    formatter: function (data) {
                        return `<p class="tool-title">${data.name} <span style="font-size: 18px;font-family:BigNum;background-clip:text;-webkit-background-clip:text;-webkit-text-fill-color:transparent;background-image:-webkit-linear-gradient(top,rgb(2,196,252) 40%,rgb(5,55,150));line-height: 18px;">${data.value}</span></p>`

                    }
                },
                legend: {
                    show: false,
                    itemWidth: 15,
                    itemHeight: 12,
                    itemGap: 16,
                    textStyle: {
                        fontSize: 13,
                        color: 'rgba(255,255,255,0)'
                    },
                },
                grid: {
                    left: '5%',
                    right: '5%',
                    bottom: '15%',
                    top: '18%',
                    containLabel: true
                },
                xAxis: {
                    name: '',
                    nameTextStyle: {
                        color: '#fff',
                        align: 'left',
                        fontSize: 14,
                        fontFamily: '优设标题黑'
                    },
                    axisLine: { show: true, lineStyle: { color: 'rgba(255,255,255,0.1)' } },
                    axisLabel: { show: true, textStyle: { color: 'rgb(255, 255, 255)', } },
                    axisTick: { show: false },
                    splitLine: { show: false, lineStyle: { color: 'rgba(73, 163, 201,0.1)' } },
                    data: this.getArrByKey(data, 'name'),
                },
                yAxis: {
                    // name: '人数（万）',
                    nameTextStyle: {
                        color: 'rgb(193, 205, 214)',
                        fontSize: 15,
                        align: 'middle',
                        lineHeight: 14,
                        fontFamily: '优设标题黑'
                    },
                    axisLine: { show: false, lineStyle: { color: 'rgba(174, 193, 227,0.1)' } },
                    axisLabel: { show: true, textStyle: { color: 'rgb(174, 193, 227)', } },
                    axisTick: { show: false },
                    splitLine: { show: true, lineStyle: { color: 'rgba(174, 193, 227,0.1)' } },
                },
                series: [
                    //下半截柱状图
                    {
                        type: 'bar',
                        barWidth: 20,
                        barGap: '-100%',
                        itemStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1,
                                    [{
                                        offset: 0,
                                        color: "rgba(82, 183, 221,0)"
                                    },
                                    {
                                        offset: 1,
                                        color: "rgba(82, 183, 221,.9)"
                                    }
                                    ],
                                    false
                                )
                            }
                        },
                        data: this.getSymbolData(data)
                    },
                    //头部菱形
                    // {
                    //     "type": "pictorialBar",
                    //     "symbol": 'diamond',
                    //     "symbolPosition": "end",
                    //     "symbolSize": [20, 10],
                    //     "symbolOffset": [0, -5],
                    //     "z": 12,
                    //     "itemStyle": {
                    //         "normal": {
                    //             color: new echarts.graphic.LinearGradient(0, 0, 0, 1,
                    //                 [{
                    //                     offset: 0,
                    //                     color: "rgba(146,150,154,.2)"
                    //                 },
                    //                 {
                    //                     offset: 1,
                    //                     color: "rgba(82, 183, 221,.5)"
                    //                 }
                    //                 ],
                    //                 false
                    //             ),
                    //         }
                    //     },
                    //     "data": bgArr
                    // },
                    //中间菱形
                    {
                        "name": "",
                        "type": "pictorialBar",
                        "symbol": 'diamond',
                        "symbolSize": [20, 10],
                        "symbolOffset": [0, -5],
                        "symbolPosition": "end",
                        "itemStyle": {
                            "normal": {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1,
                                    [{
                                        offset: 0,
                                        color: "rgba(82, 183, 221,1)"
                                    },
                                    {
                                        offset: 1,
                                        color: "rgba(82, 183, 221,1)"
                                    }
                                    ],
                                    false
                                ),
                            }
                        },
                        "z": 12,
                        "data": this.getArrByKey(data, 'value')
                    },
                    {
                        name: '2019',
                        type: 'bar',
                        barWidth: 20,
                        // barGap: '-100%',    
                        stack: '柱状图高度',
                        "itemStyle": {
                            "normal": {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 0,
                                    [{
                                        offset: 0,
                                        color: "rgba(146,150,154,.1)"
                                    },
                                    {
                                        offset: 1,
                                        color: "rgba(146,150,154,.1)"
                                    }
                                    ],
                                    false
                                ),
                            }
                        },
                        data: bgArr
                    }
                ]
            };
            var container = document.getElementById('containerHistogram');
            /*初始化echarts 容器对象
                    init函数中参数
                    	
            */
            var myCharts = echarts.init(container);
            myCharts.setOption(option);
            // return option;


        }
    }
}
</script>
<style lang="">
    
</style>