<!-- 飞控中心鹰巢 -->
<!-- 电池 -->
<template>
  <div class="cpt-observe-mspace-dashboard-battery jcsb">
    <div class="battery-inner">

      <div class="size-wrap">
        <div class="size-box">
          <div class="size-inner">
            <div class="dianchigai"></div>
            <div class="size-inner-box" :style="`height: ${battery.size || 0}%; background-color: ${
                battery.state.color
              };`"></div>
          </div>
        </div>
      </div>
      <div class="mt11 ml15">
        <div class="voltage-box">
          <svg width="40px" height="11px">
            <text x="20" y="10" style="text-anchor: middle; font-size: 10px; fill: #b3bbc5">
              当前电压
            </text>
          </svg>
          <div class="voltage-value">{{ battery.voltage||0 }}</div>
          <div class="voltage-unit">V</div>
        </div>
        <div class="status-wrap">
          <div class="status-box" :style="`background-color: ${battery.state.color};`">
            <svg width="40px" height="14px">
              <text x="20" y="10" style="text-anchor: middle; font-size: 10px; fill: #fff">
                {{ battery.state.label }}
              </text>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
        'flyData': {
            type: Object,
            default: () => "middle",
        }
    },
    data() {
      return {
        battery: {
          state: { label: "低电量", color: "#f73939" },
        },
        state: {
          1: { label: "满电", color: "#76c230" },
          2: { label: "良好", color: "#FFBD36" },
          3: { label: "低电量", color: "#f73939" },
        },
      };
    },
    mounted() {
      3
    },
    watch: {
      flyData: {
        // 低电量，良好，
        handler(value) {
          console.log(value,'value');
          let uavInfo = value;
          if (uavInfo) {
            let size = uavInfo.voltage
            let state = this.state["3"];
            if (size >= 26) {
              state = this.state["1"];
              size = 100
            } else if (size < 26 && size > 23) {
              state = this.state["2"];
              size = 50
            } else if (size <= 23) {
              state = this.state["3"];
            }
            let voltage = uavInfo.voltage&&uavInfo.voltage.toFixed(1)
            this.battery = {
              size,
              state,
              voltage //: voltage <= 42 ? 42 : voltage,
            };
          }
        },
        deep: true,
        immediate: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .cpt-observe-mspace-dashboard-battery {
    width: 96px;
    height: 100%;
    box-sizing: border-box;

    .battery-inner {
      display: flex;
      position: relative;

      /* &::before {
      width: 1px;
      height: 90px;
      content: " ";
      display: block;
      position: absolute;
      right: 0;
      top: 50%;
      background-image: linear-gradient(
        206deg,
        rgba(36, 146, 252, 0) 5%,
        #2492fc 56%,
        rgba(36, 146, 252, 0) 100%
      );
      transform: translateY(-50%);
    } */
      .voltage-box {
        width: 100%;
        box-sizing: border-box;
        /* padding-left: 30px; */
        text-align: center;

        .voltage-value {
          font-size: 16px;
          color: #93d755;
          font-weight: 400;
          margin-top: 3px;
        }

        .voltage-unit {
          font-family: MicrosoftYaHei;
          font-size: 10px;
          color: #b3bbc5;
          letter-spacing: 0;
          font-weight: 400;
        }
      }

      .status-wrap {

        /* padding-left: 40px; */
        .status-box {
          width: 44px;
          height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .size-wrap {
        margin-top: 11px;

        /* padding-left: 55px; */
        .size-box {
          box-sizing: border-box;
          width: 28px;
          height: 73px;

          .size-inner {
            margin-top: 8px;
            width: 100%;
            height: calc(100% - 8px);
            border: 1px solid #50abff;
            box-sizing: border-box;
            border-radius: 4px;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding: 2px;
            position: relative;
            .dianchigai{
              position: absolute;
              left: 2px;
              top:2px;
              width: 89%;
              height: calc(100% - 3px);
              // border: 1px solid red;
              background-image: linear-gradient(to right,#fff -14%,transparent 41%);
              border-radius: 3px;
            }
            &::before {
              content: " ";
              display: block;
              position: absolute;
              top: -5px;
              left: 50%;
              width: 10px;
              height: 4px;
              border-top-left-radius: 3px;
              border-top-right-radius: 3px;
              border: 1px solid #50abff;
              border-bottom: 0px;
              transform: translateX(-50%);
            }

            .size-inner-box {
              width: 100%;
              border-radius: 4px;
            }
          }
        }
      }
    }
  }
</style>